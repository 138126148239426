import * as React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import ProgressStep from './ProgressStep';

import css from './FlowNavigation.module.scss';

const flowSteps = [
  {
    path: 'target-view',
    label: 'Target view',
  },
  {
    path: 'campaign-view',
    label: 'Campaign view',
  },
];

const FlowNavigation = React.memo(() => {
  const location = useLocation();

  const pathName = location.pathname === '/campaign-debug' ? '/campaign-view' : location.pathname;
  const flowStepsWithField = flowSteps.map((flow) => ({
    ...flow,
    disabled: false,
    path: `/${flow.path}`,
  }));

  const currentStep = flowStepsWithField.findIndex((step) => pathName.indexOf(step.path) > -1);

  return (
    <div className={classNames('flow-nav', css.root)}>
      <ul className="bx--progress">
        {flowStepsWithField?.map((step, index) => (
          <ProgressStep
            href={step.path}
            label={step.label}
            key={step.label}
            disabled={step.disabled}
            current={index === currentStep}
            complete={index < currentStep}
          />
        ))}
      </ul>
    </div>
  );
});

export { FlowNavigation };
