import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  ComposedModal, ModalBody, ModalFooter, ModalHeader,
} from 'carbon-components-react';


interface LoadingTimeWarningDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAccept: () => void;
}

const LoadingTimeWarningDialog: React.FC<LoadingTimeWarningDialogProps> = (props): React.ReactElement => {
  const {
    open, setOpen, onAccept,
  } = props;

  const handleAccept = () => {
    onAccept();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className='dark-theme feedback-dialog'
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalHeader title="Notice: long loading time" />
      <ModalBody>
        Loading all wells might take a while, are you sure?
      </ModalBody>
      <ModalFooter
        secondaryButtonText="Cancel"
        onRequestClose={handleCancel}
        primaryButtonText="Accept"
        onRequestSubmit={handleAccept}
      />
    </ComposedModal>,
    document.body,
  );
};

export { LoadingTimeWarningDialog };
