import * as React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  Button,
  ButtonSet,
} from 'carbon-components-react';

import { useApplicationContext } from '../../context/ApplicationContext';
import { AcknowledgementModal } from './AcknowledgemnetModal';
import { useCreateCampaign, useUpdateCampaign } from '../../hooks/campaign';
import { CampaignNameDialog } from '../organisms/CampaignNameDialog';
import { TargetWell } from '../../types/Targets';
import { useNegativeTriggerEffect } from '../../hooks/NegativeTriggeredEffect';

import css from './WellInfo.module.scss';
import { exportWells } from 'utils/wellExport';

const WellInfo = React.memo(() => {
  const {
    getSelectedWell,
    getEditWell,
    selectedWell,
    editWell,
    campaignWells,
    setCampaignWells,
    setNewNotification,
    selectedCampaign,
    setEditWell,
    setSelectedWell,
    targetsData,
  } = useApplicationContext();
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const { createCampaign } = useCreateCampaign();
  const { isUpdating, updateCampaign, updateError } = useUpdateCampaign();
  const [isAddOpen, setAddOpen] = React.useState<boolean>(false);
  const wellData = getSelectedWell();
  const editWellData = getEditWell();

  const wellExistsInCampaign = (): boolean => {
    const result = campaignWells.find(
      (well) => well.target === wellData?.target.title && wellData?.well.id === well.id,
    );

    return !!result;
  };

  const handleCreateCampaign = (name: string) => {
    setCampaignWells([wellData!.well]);

    createCampaign(name, [
      {
        target_name: wellData!.target.title,
        well_id: wellData!.well.wellId,
      },
    ]);
  };

  const handleReplaceInCampaign = () => {
    setOpen(true);
  };

  const replaceInCampaign = () => {
    const removed = campaignWells.filter(
      (campaignWell) => campaignWell.id !== editWell!.wellId || campaignWell.target !== editWell!.target,
    );

    setEditWell(null);
    setSelectedWell(null);
    setCampaignWells([...removed, wellData!.well]);
  };

  const handleAddToCampaign = () => {
    if (!selectedCampaign) {
      setAddOpen(true);
      return;
    }

    setNewNotification('Well added to campaign');

    const updatedCampaignWells = [...campaignWells, wellData!.well];

    setCampaignWells(updatedCampaignWells);

    const wellList = updatedCampaignWells.map((well: TargetWell) => ({
      target_name: well.area, // target_name would be more accurately called file_name
      well_id: well.wellId,
    }));

    updateCampaign(selectedCampaign.id!, selectedCampaign.name, wellList);
  };

  useNegativeTriggerEffect(() => {
    if (updateError) {
      setNewNotification('Error saving campaign', 'error');
    }
  }, isUpdating);

  if (!wellData) {
    return null;
  }

  const editWellIsSelectedWell = () => {
    if (!editWell || !selectedWell) {
      return false;
    }

    return editWell.wellId === selectedWell.wellId && editWell.target === selectedWell.target;
  };

  const handleExportWell = () => {
    const { well } = wellData;
    exportWells([well], well.name)
  };

  return (
    <div className={css.root}>
      <TableContainer className={css.wellInfoContainer}>
        <Table size="short">
          <TableHead>
            <TableRow>
              <TableHeader>Property</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableRow>
          </TableHead>
          {wellData && (
            <TableBody>
              <TableRow>
                <TableCell>Target</TableCell>
                <TableCell>{wellData.well.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Max dls shallowest</TableCell>
                <TableCell>{wellData.well.maxDlsShallowestSection?.toFixed(1)} deg/30m</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Max dls deepest</TableCell>
                <TableCell>{wellData.well.maxDlsDeepestSection?.toFixed(1)} deg/30m</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Max azimuth change</TableCell>
                <TableCell>{wellData.well.azimuthChange?.toFixed(1)} deg</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Well length</TableCell>
                <TableCell>{wellData.well.maxMD?.toFixed(0)} m</TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {wellData && (
        <>
          <ButtonSet>
            <Button
              className={css.replaceButton}
              disabled={wellExistsInCampaign() || !editWell || editWellIsSelectedWell()}
              onClick={handleReplaceInCampaign}
              kind="secondary"
            >
              Replace in campaign
            </Button>
            <Button className={css.addButton} disabled={wellExistsInCampaign()} onClick={handleAddToCampaign}>
              Add to campaign
            </Button>
          </ButtonSet>
          <Button className={css.exportButton} onClick={handleExportWell}>Export</Button>
        </>
      )}
      <AcknowledgementModal
        message={`This will replace the ${editWellData?.well.name} for the target ${editWellData?.target.title}`}
        open={isOpen}
        setOpen={setOpen}
        onAccept={replaceInCampaign}
      />
      <CampaignNameDialog
        message="First create a campaign into which you want to insert this well"
        open={isAddOpen}
        setOpen={setAddOpen}
        onAccept={handleCreateCampaign}
      />
    </div>
  );
});

WellInfo.displayName = 'WellInfo';

export { WellInfo };
