import * as React from 'react';
import { OverflowMenu, OverflowMenuItem } from 'carbon-components-react';

import { CaretDown20, CaretSort20, CaretUp20 } from '@carbon/icons-react';

import { CampaignSorting } from '../templates/CampaignInfo';

import css from './TargetMenu.module.scss';

interface TargetMenuProps {
  setSorting: (sorting: CampaignSorting) => void;
}

const TargetMenu = React.memo<TargetMenuProps>((props) => {
  const { setSorting } = props;
  const handleSortUp = () => {
    setSorting(CampaignSorting.TargetDesc);
  };
  const handleSortDown = () => {
    setSorting(CampaignSorting.TargetAsc);
  };

  return (
    <OverflowMenu ariaLabel="Target" className={css.root} renderIcon={CaretSort20} selectorPrimaryFocus="">
      <OverflowMenuItem
        className={css.menuItem}
        onClick={handleSortUp}
        itemText={(
          <>
            <CaretUp20 className={css.optionIcon} /> Sort up
          </>
        )}
      />
      <OverflowMenuItem
        className={css.menuItem}
        onClick={handleSortDown}
        itemText={(
          <>
            <CaretDown20 className={css.optionIcon} /> Sort down
          </>
        )}
      />
    </OverflowMenu>
  );
});

TargetMenu.displayName = 'TargetMenu';

export { TargetMenu };
