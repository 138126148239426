import * as React from 'react';

import css from './LoadingBar.module.scss';
import { useApplicationContext } from '../../context/ApplicationContext';

interface LoadingBarProps {
  percentage?: number;
}

const LoadingBar = React.memo<LoadingBarProps>((props) => {
  const { percentage = 0 } = props;

  return (
    <div className={css.root}>
      <h3 className={css.content}>Loading data</h3>
      <div className={css.barContainer}>
        <div className={css.bar} style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
});

export { LoadingBar };
