import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Color, Vector3 } from 'three';
import { Canvas } from 'react-three-fiber';

import {
  Constraint,
  Faults,
  Horizons,
  depthColor9,
  WellsLine2,
  CameraControls,
  useCameraControls,
  Points,
  getWellsGeometry,
  HorizonLegend,
} from '@whitespace/app-3dviewer';

import { viewport } from '../../config.json';
import { useApplicationContext } from '../../context/ApplicationContext';
import { GLOBAL_SCALE } from '../../hooks/TargetsLoader';
import Toolbox from './Toolbox';
import { useConstrainedTargetData } from '../../hooks/ConstrainedTargetData';

import css from './Viewport.module.scss';
import { useElementDimensions } from '../../hooks/Dimensions';

const WELL_HOVER_COLOR = new Color('blue');
const WELL_COLOR = new Color('yellow');
const MANUAL_WELL_COLOR = new Color('#313C5E');
const WELL_SELECTED_COLOR = new Color('#F0C');

const Viewport = ({ targetData, isCampaignView = false, trigger }) => {
  const gizmoContainer = useRef(null);
  const ref = useRef(null);
  const dimensions = useElementDimensions(ref, trigger);
  const [verticalScale, setVerticalScale] = useState(1);
  const [targetVisibility, setTargetVisibility] = React.useState('none');
  const [manualWellsVisible, setManualWellsVisible] = React.useState(true);
  const [existingWellsHighlighted, setExistingWellsHighlighted] = React.useState(false);
  const [transparentHorizons, setTransparentHorizons] = React.useState(false);
  const [transparentFaults, setTransparentFaults] = React.useState(false);

  const toggleTransparency = (transparencyType) => {
    if (transparencyType === 'horizons') {
      setTransparentHorizons(!transparentHorizons);
      return;
    }

    if (transparencyType === 'faults') {
      setTransparentFaults(!transparentFaults);
    }
  };

  const {
    loadedFieldObjects,
    setLoadedFieldObjects,
    objectsOffset,
    targetsData,
    selectedWell,
    getSelectedWell,
    horizonObject,
    setSelectedWell,
    selectedTargets,
    campaignWells,
    getSelectedTarget,
    manualWellsData,
    horizons,
    setHorizons,
  } = useApplicationContext();
  const { constrainedSelectedTargetsData } = useConstrainedTargetData();
  const selectedTarget = getSelectedTarget();

  const visibleIds = targetData.length ? targetData[0].visibleIds : [];

  const setInitialCameraAndTarget = useCallback((target) => {
    useCameraControls.setState({
      cameraPosition: new Vector3(target.x, 0, target.z + 100),
      orbitControlsTarget: target,
    });
  }, []);

  const horizonsMinMax = React.useMemo(() => {
    const visibleHorizons = loadedFieldObjects?.filter((fd) => fd.type === 'horizon' && fd.visible);

    if (visibleHorizons?.length === 1) {
      const { boundingBox } = visibleHorizons[0].geometry[0];
      return { minY: boundingBox.min.y, maxY: boundingBox.max.y };
    }
    return undefined;
  }, [loadedFieldObjects]);

  useEffect(() => {
    setTimeout(() => {
      if (selectedTarget) {
        setInitialCameraAndTarget(selectedTarget.geometry.boundingSphere.center);
      }
    }, 50);
  }, [selectedTargets, targetsData]);

  useEffect(() => {
    if (targetsData && targetsData.length) {
      setInitialCameraAndTarget(targetsData[0].geometry.boundingSphere.center, true);
    }
  }, [targetsData]);

  const handleSelectWell = (target) => (well) => {
    if (!target) {
      return;
    }

    setSelectedWell({
      target,
      wellId: well.id,
    });
  };

  const handleToggleManualWellsVisible = () => {
    setManualWellsVisible(!manualWellsVisible);
  };
  
  const handleToggleExistingWellsHighlighted = () => {
    setExistingWellsHighlighted(!existingWellsHighlighted);
  };

  const toggleHorizonVisibility = (toggledHorizon) => {
    setHorizons(
      horizons.map((horizon) =>
        (horizon.id === toggledHorizon.id
          ? {
            ...toggledHorizon,
            visible: !toggledHorizon.visible,
          }
          : horizon)),
    );
  };

  const toggleVisibility = (f) => {
    setLoadedFieldObjects(
      loadedFieldObjects.map((fieldObject) =>
        (fieldObject.id === f.id
          ? {
            ...fieldObject,
            visible: !fieldObject.visible,
          }
          : fieldObject)),
    );
  };

  const wellCount = targetsData.reduce((currentWellCount, target) => currentWellCount + target.wells.length, 0);
  const displayedCount = constrainedSelectedTargetsData.length
    ? constrainedSelectedTargetsData.reduce((acc, target) => acc + target.filteredWells.length, 0)
    : 0;

  const handleRecenter = () => {
    if (selectedTarget) {
      setInitialCameraAndTarget(selectedTarget.geometry.boundingSphere.center);
    }
  };

  const handlePointOver = (well) => {
    if (isCampaignView) {
      setSelectedWell({
        target: well.area,
        wellId: well.id,
      });
    }
  };

  const handlePointOut = () => {
    if (isCampaignView) {
      setSelectedWell(null);
    }
  };

  const getTotalCount = () => {
    if (selectedTargets.length === 0) {
      return null;
    }

    return targetsData
      .filter((target) => selectedTargets.includes(target.title))
      .reduce((sum, target) => sum + target.flippedWellCount + target.wellCount, 0);
  };

  // const visibleManualWells = selectedTargets.map((findTarget) => {
  //   const targetParts = findTarget.split('_');
  //   const subTarget = targetParts.slice(0, 2).join('_');

  //   const well = manualWellsData.wells.find((manualWell) => {
  //     return true
  //     const nameParts = manualWell.name.split('_');
  //     const subName = nameParts.slice(0, 2).join('_');

  //     return subTarget === subName;
  //   });

  //   return well?.id;
  // });

  const visibleManualWells = manualWellsData?.wells.map(well => well.id)

  const wellCountTypeLabel = 'Well count current target';

  return (
    <div ref={ref} className={css.root}>
      <div className={css.wellCount}>
        <div>Well count - all targets: {wellCount}</div>
        {selectedTarget ? (
          <div>
            {wellCountTypeLabel} {displayedCount} (of {getTotalCount()})
          </div>
        ) : (
          <div>{wellCountTypeLabel} 0</div>
        )}
        <div>Well count - current campaign: {campaignWells.length}</div>
      </div>
      <div className={css.gizmo}>
        <div ref={gizmoContainer} />
      </div>
      <Toolbox
        parentDimensions={dimensions}
        manualWellsVisible={manualWellsVisible}
        toggleManualWellsVisible={handleToggleManualWellsVisible}
        existingWellsHighlighted={existingWellsHighlighted}
        toggleExistingWellsHighlighted={handleToggleExistingWellsHighlighted}
        toggleHorizonVisibility={toggleHorizonVisibility}
        horizons={horizons}
        onRecenter={handleRecenter}
        fieldObjects={loadedFieldObjects}
        scale={verticalScale}
        onScaleChange={setVerticalScale}
        onFieldObjectVisibilityToggle={toggleVisibility}
        targetVisibility={targetVisibility}
        setTargetVisibility={setTargetVisibility}
        transparentFaults={transparentFaults}
        transparentHorizons={transparentHorizons}
        toggleTransparency={toggleTransparency}
      />
      <HorizonLegend offset={objectsOffset} scale={GLOBAL_SCALE} />
      <Canvas
        id="canvasDomElement"
        raycaster={{ params: { Line: { threshold: viewport.canvas.raycast } } }}
        style={{ ...viewport.style }}
      >
        <pointLight />
        <CameraControls gizmo={gizmoContainer?.current} offset={50} />
        <group scale={[1, verticalScale, 1]}>
          {loadedFieldObjects?.map((fd) => {
            switch (fd.type) {
              case 'constraint':
                return (
                  <Constraint
                    key={fd.id}
                    name="constraint"
                    constraint={fd.rawdata}
                    geometry={fd.geometry[0]}
                    color="orange"
                    visible={fd.visible}
                    interaction={false}
                    scale={GLOBAL_SCALE}
                    offset={objectsOffset}
                  />
                );
              case 'faults':
                return (
                  <Faults
                    opacity={transparentFaults ? 0.5 : 1}
                    key={fd.id}
                    name={fd.title}
                    geometry={fd.geometry[0]}
                    offset={objectsOffset}
                    scale={GLOBAL_SCALE}
                    color="lightblue"
                    visible={fd.visible}
                    interaction={false}
                  />
                );
              default:
                return null;
            }
          })}
          {horizonObject && (
            <Horizons
              opacity={transparentHorizons ? 0.5 : 1}
              colors={depthColor9}
              name="Horizons"
              visibleHorizons={horizons.map((horizon) => horizon.visible)}
              horizons={horizonObject.rawdata}
              geometries={horizonObject.geometry}
              offset={objectsOffset}
              scale={GLOBAL_SCALE}
              visible
              interaction={false}
            />
          )}
          {targetData.map((target) => {
            const targetName = target.title;

            return (
              <React.Fragment key={target.title}>
                <WellsLine2
                  key={`wells-${target.id}`}
                  wells={target.wells}
                  name={target.title}
                  geometry={target.geometry}
                  offset={objectsOffset}
                  scale={GLOBAL_SCALE}
                  hoverColor={WELL_HOVER_COLOR}
                  color={WELL_COLOR}
                  visible={target.visible}
                  lineWidth={2}
                  selectedLineWidth={5}
                  onClick={handleSelectWell(targetName)}
                  selectedColor={WELL_SELECTED_COLOR}
                  selectedIds={selectedWell ? [selectedWell.wellId] : []}
                  visibleIds={target.visibleIds}
                  onPointerOver={handlePointOver}
                  onPointerOut={handlePointOut}
                />
              </React.Fragment>
            );
          })}
          {manualWellsData && (
            <WellsLine2
              key="wells-manual"
              wells={manualWellsData.wells}
              name="Manual wells"
              geometry={manualWellsData.geometry}
              offset={objectsOffset}
              scale={GLOBAL_SCALE}
              hoverColor={WELL_HOVER_COLOR}
              color={existingWellsHighlighted ? WELL_SELECTED_COLOR : MANUAL_WELL_COLOR}
              visible={manualWellsVisible}
              visibleIds={visibleManualWells}
              interaction={false}
              lineWidth={1}
              selectedLineWidth={5}
            />
          )}
        </group>
      </Canvas>
    </div>
  );
};

export { Viewport };
