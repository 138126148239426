import { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from './axios';
import { CampaignPayload } from '../hooks/campaign';

export const fetchAllCampaigns = (field: string): Promise<AxiosResponse<Array<CampaignPayload>>> => {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    params: {
      field,
    },
  };

  return axios.get<Array<CampaignPayload>>(`/campaigns`, config);
};

export const createCampaign = (payload: CampaignPayload): Promise<AxiosResponse<CampaignPayload>> => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axios.post<CampaignPayload>('/campaigns', payload, config);
};

export const updateCampaign = (payload: CampaignPayload) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axios.put<CampaignPayload>(`/campaigns/${payload.id!}`, payload, config);
};

export const removeCampaign = (campaignId: number) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axios.delete(`/campaigns/${campaignId}`, config);
};
