import axios from 'axios';

import { getReactAppAPIURL } from '../utils/getWindowConfigOption';

const instance = axios.create({
  baseURL: `${getReactAppAPIURL()}`,
});

export default instance;

export const axiosCancelToken = axios.CancelToken;
