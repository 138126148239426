import * as React from 'react';

export const useNegativeTriggerEffect = (callback: React.EffectCallback, trigger: boolean): void => {
  const [postInitialCall, setPostInitialCall] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!postInitialCall) {
      setPostInitialCall(true);
    } else if (!trigger) {
      return callback();
    }

    return undefined;
  }, [trigger]);
};
