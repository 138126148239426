import * as React from 'react';

import { AppHeader } from '../../components/templates/AppHeader';
import { FlowNavigation } from '../../components/organisms/FlowNavigation';
import { CampaignContent } from '../../components/templates/CampaignContent';
import { PageContent } from '../../components/templates/PageContent';
import { CampaignInfo } from '../../components/templates/CampaignInfo';
import { Credits } from '../../components/templates/Credits';

import css from './CampaignView.module.scss';

const CampaignView = React.memo(() => (
  <div>
    <AppHeader />
    <FlowNavigation />
    <PageContent className={css.content}>
      <CampaignInfo />
      <CampaignContent />
    </PageContent>
    <Credits />
  </div>
));

CampaignView.displayName = 'CampaignView';

export { CampaignView };
