import * as React from 'react';
import { Grid } from 'carbon-components-react';
import classNames from 'classnames';

import { Viewport } from '../viewport/Viewport';
import { Tile } from '../organisms/Tile';
import { useConstrainedTargetData } from '../../hooks/ConstrainedTargetData';
import { TargetScatterPlot } from '../organisms/TargetScatterPlot';
import { TargetMap } from '../organisms/TargetMap';
import { WellSideView } from '../organisms/WellSideView';
import { SwitchIcon } from '../atoms/SwitchIcon';
import { useApplicationContext } from '../../context/ApplicationContext';

import css from './TargetContent.module.scss';

const TargetContent = React.memo(() => {
  const { constrainedSelectedTargetsData } = useConstrainedTargetData();
  const { selectedWell } = useApplicationContext();
  const [expanded, setExpanded] = React.useState<string>('viewport');
  const [tileTrigger, setTileTrigger] = React.useState<boolean>(false);
  const gridRef = React.useRef<HTMLDivElement>(null);
  const [isSideViewVisible, setSideViewVisible] = React.useState<boolean>(false);

  const handleSetExpanded = (name: string) => {
    setExpanded(name);
  };

  React.useEffect(() => {
    if (!selectedWell) {
      setSideViewVisible(false);
    }
  }, [selectedWell]);

  const handleToggleSideViewVisible = () => {
    if (!selectedWell) {
      setSideViewVisible(false);
      return;
    }

    setSideViewVisible(!isSideViewVisible);
  };

  const handleResize = () => {
    if (gridRef.current) {
      const grid = gridRef.current.firstElementChild as HTMLDivElement;
      const gridBoundingClientRect = grid!.getBoundingClientRect();
      const gridWidth = gridBoundingClientRect.width;
      const mapTile = grid!.children[2] as HTMLDivElement;
      const boundingClientRect = mapTile.getBoundingClientRect();
      const width = gridWidth - boundingClientRect.height;

      if (expanded !== 'viewport') {
        grid!.style.gridTemplateColumns = `${boundingClientRect.height}px 1fr `;
      } else {
        grid!.style.gridTemplateColumns = `${width - 3 * 16}px 1fr`;
      }

      setTileTrigger(!tileTrigger);
    }
  };

  React.useEffect(() => {
    handleResize();

    setTileTrigger(!tileTrigger);
  }, [expanded]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className={css.root} ref={gridRef}>
      <Grid className={classNames(css.grid, css[`expand_${expanded}`])} fullWidth>
        <Tile
          expanded={expanded}
          className={css.viewport}
          name="viewport"
          collapse="map"
          onSetExpanded={handleSetExpanded}
          iconColor="white"
        >
          <Viewport trigger={tileTrigger} targetData={constrainedSelectedTargetsData} />
        </Tile>

        <Tile
          expanded={expanded}
          className={css.map}
          name="map"
          collapse="viewport"
          onSetExpanded={handleSetExpanded}
          iconColor="white"
        >
          <TargetMap tileTrigger={tileTrigger} />
        </Tile>
        <Tile 
          expanded={expanded}
          className={css.scatterPlot}
          name="scatterPlot"
          collapse="viewport"
          onSetExpanded={handleSetExpanded}
          iconColor="black"
        >
          {/* <SwitchIcon onClickHandle={handleToggleSideViewVisible} disabled={!selectedWell} /> */}
          {isSideViewVisible ? (
            <WellSideView tileTrigger={tileTrigger} />
          ) : (
            <TargetScatterPlot tileTrigger={tileTrigger} />
          )}
        </Tile>
      </Grid>
    </div>
  );
});

TargetContent.displayName = 'TargetContent';

export { TargetContent };
