import * as React from 'react';
import classNames from 'classnames';

import css from './RangeDisplay.module.scss';
import { Tooltip } from './Tooltip';

const DOT_OFFSET = 5;

interface RangeDisplayProps {
  rangeMin: number;
  rangeMax: number;
  rangeMinTip: string;
  rangeMaxTip: string;

  value: number;
  valueMax?: number;

  valueTip: string;
  valueMaxTip?: string;

  width: number;
}

const RangeDisplay = React.memo<RangeDisplayProps>((props) => {
  const {
    rangeMax, rangeMin, valueMax, value, width, rangeMaxTip, rangeMinTip, valueMaxTip, valueTip,
  } = props;

  const rangeDelta = rangeMax - rangeMin;
  const offset = ((value - rangeMin) / rangeDelta) * width;
  const offsetMax = typeof valueMax !== 'undefined' ? ((valueMax - rangeMin) / rangeDelta) * width : null;

  return (
    <div style={{ width: `${width + 2 * DOT_OFFSET}px` }} className={css.root}>
      <div className={css.axis} style={{ width: `${width}px` }} />
      <Tooltip position="top" text={rangeMinTip}>
        <div className={classNames(css.dot, css.start)} />
      </Tooltip>

      <Tooltip position="top" text={rangeMaxTip}>
        <div className={classNames(css.dot, css.end)} />
      </Tooltip>

      <Tooltip position="top" text={valueTip}>
        <div className={classNames(css.dot, css.value)} style={{ left: `${offset}px` }} />
      </Tooltip>

      {offsetMax !== null && (
        <Tooltip position="top" text={valueMaxTip}>
          <div className={classNames(css.dot, css.valueMax)} style={{ left: `${offsetMax}px` }} />
        </Tooltip>
      )}
    </div>
  );
});

RangeDisplay.displayName = 'RangeDIsplay';

export { RangeDisplay };
