import * as React from 'react';
import { Header as CarbonHeader } from 'carbon-components-react/lib/components/UIShell';

import css from './Header.module.scss';

const Header: React.FC = (props): React.ReactElement => {
  const { children } = props;

  return (
    <CarbonHeader className={css.root} aria-label="project header">
      {children}
    </CarbonHeader>
  );
};

export { Header };
