import React from 'react';
import { ToastNotification } from 'carbon-components-react';
import { useApplicationContext } from '../../context/ApplicationContext';

import css from './Notification.module.scss';

function Notification() {
  const {
    showNotification, setShowNotification, notificationID, notificationType,
  } = useApplicationContext();
  const [timer, setTimer] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    const newTimer = window.setTimeout(() => {
      setShowNotification('');
      setTimer(null);
    }, 5000);

    setTimer(newTimer);

    return () => {
      clearTimeout(newTimer);
    };
  }, [notificationID]);

  const title = !notificationType ? 'Success' : 'Error';
  const kind = !notificationType ? 'success' : 'error';

  return (
    <>
      {showNotification && (
        <ToastNotification
          className={css.root}
          {...{
            title,
            kind,
            hideCloseButton: true,
            caption: `${showNotification}`,
          }}
        />
      )}
    </>
  );
}

export default Notification;
