import * as React from 'react';

import { getPointsGeometry } from '@whitespace/app-3dviewer';

import { Field, useApplicationContext } from '../context/ApplicationContext';
import { GLOBAL_SCALE } from './TargetsLoader';
import { Target, TargetWell } from '../types/Targets';

export const useConstrainedTargetData = () => {
  const {
    field,
    constraints,
    selectedTargets,
    targetsData,
    objectsOffset,
    allowReroutingAccessRoads,
    allowReroutingCathodicFeatures,
    allowReroutingBuriedPowerlines,
    onlyOutsidePolymerInjectionArea,
  } = useApplicationContext();

  const constrainedSelectedTargetsData = React.useMemo<Target[]>(() => {
    const selectedTargetsData = targetsData.filter((targetData) => selectedTargets.includes(targetData.title));

    const wellFilter = (well: TargetWell) =>
      well.easting <= constraints.easting &&
      well.northing <= constraints.northing &&
      well.wellCost <= constraints.wellCost &&
      well.drainLength <= constraints.drainLength &&
      well.wellLength <= constraints.wellLength &&
      well.minCCDistanceOverburden <= constraints.minCCDistanceOverburden &&
      well.minCCDistanceDrain <= constraints.minCCDistanceDrain &&
      well.outstep <= constraints.outstep &&
      well.maxDlsDeepestSection <= constraints.maxDlsDeepestSection &&
      well.maxDlsShallowestSection <= constraints.maxDlsShallowestSection &&
      well.maxAzimuthalChange <= constraints.maxAzimuthalChange &&
      well.kickOffDepth <= constraints.kickOffDepth &&
      // when checked show all, else only show the ones where not rerouted
      (allowReroutingAccessRoads || !well.rerouteAccessRoads) &&
      (allowReroutingCathodicFeatures || !well.rerouteCathodicFeatures) &&
      (allowReroutingBuriedPowerlines || !well.rerouteBuriedPowerlines) &&
      (!onlyOutsidePolymerInjectionArea || !well.isInPolymerPoly);

    const wellInjFilter = (well: TargetWell) =>
      well.easting <= constraints.easting &&
      well.northing <= constraints.northing &&
      well.wellCost <= constraints.wellCost &&
      well.drainLength <= constraints.drainLength &&
      well.wellLength <= constraints.wellLength &&
      well.minCCDistanceOverburden <= constraints.minCCDistanceOverburden &&
      well.minCCDistanceDrain <= constraints.minCCDistanceDrain &&
      well.outstep <= constraints.outstep &&
      well.maxDlsShallowestSection <= constraints.maxDlsShallowestSection &&
      well.maxAzimuthalChange <= constraints.maxAzimuthalChange &&
      well.kickOffDepth <= constraints.kickOffDepth &&
      (!onlyOutsidePolymerInjectionArea || !well.isInPolymerPoly);

    return selectedTargetsData.map((data: Target) => {
      const filteredData = data.wells.filter(field === Field.NIMR_E_INJ ? wellInjFilter : wellFilter);

      return {
        ...data,
        filteredWells: filteredData,
        visibleIds: filteredData.map((well) => well.id),
      };
    });
  }, [
    constraints,
    selectedTargets,
    allowReroutingAccessRoads,
    allowReroutingCathodicFeatures,
    allowReroutingBuriedPowerlines,
    onlyOutsidePolymerInjectionArea,
  ]);

  return { constrainedSelectedTargetsData };
};
