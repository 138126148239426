import React, {
  useEffect, useMemo, useState, FC, ReactElement,
} from 'react';
import * as ReactDOM from 'react-dom';
import {
  Button, ComposedModal, ModalBody, ModalHeader, TextArea, TextInput,
} from 'carbon-components-react';

import { CheckmarkFilled32 } from '@carbon/icons-react';

import axios from '../../api/axios';
import { urls } from '../../config.json';

const submittedMessages = [
  'Thank you! Time for a very well deserved ☕.',
  'Thank you. You’re a hero 💪! ',
  'Thank you! Your feedback will be sent over by the first available postal 🐦.',
  'Thank you 🎉! Everytime we receive feedback we do a little 🕺.',
  'Thank you. You 🤘.',
  'Every piece of feedback is a gift 🎁 - thank you.',
  'Thank you, you awesome human 😎.',
  'Good feedback creates good karma 🧘‍♀️. Thank you.',
  'High fives 🖐! Thanks for sharing your feedback.',
  'Thanks for sharing! Our army of busy 🐝s salutes you.',
];

interface FeedbackDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const sample = (array: string[]): string => {
  const { length } = array;

  return length ? array[Math.floor(Math.random() * length)] : '';
};

const FeedbackDialog: FC<FeedbackDialogProps> = ({ open, setOpen }): ReactElement => {
  const [name, setName] = useState<string>('');
  const [feedback, setFeedback] = useState<string>('');

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittedMessage, setSubmittedMessage] = useState<string>('');

  const formValid = useMemo<boolean>(() => name.length > 0 && feedback.length > 0, [name, feedback]);

  const onSubmit = async () => {
    if (formValid) {
      const feedbackMessage = {
        text: `Project: PDO Nimr, Name: ${name}, Feedback: ${feedback}`,
      };

      try {
        await axios.post(urls.slack_api, JSON.stringify(feedbackMessage));
        setSubmitted(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    }
  };

  useEffect(() => {
    if (open) {
      setFeedback('');
      setSubmittedMessage(sample(submittedMessages));
      setSubmitted(false);
    }
  }, [open]);

  return ReactDOM.createPortal(
    <ComposedModal
      className="dark-theme feedback-dialog"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalHeader title={submitted ? '' : 'Suggestions? Please share - your feedback matters.'} />
      <ModalBody>
        {submitted ? (
          <div className="submitted-wrapper">
            <div className="submitted-icon">
              <CheckmarkFilled32 />
            </div>
            <div className="submitted-message">{submittedMessage}</div>
          </div>
        ) : (
          <>
            <fieldset className="bx--fieldset">
              <TextInput
                id="feedback__name"
                labelText="Your name"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                maxLength={100}
              />
            </fieldset>
            <fieldset className="bx--fieldset">
              <TextArea
                id="feedback__message"
                labelText="Feedback"
                placeholder="Enter your message"
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                maxLength={250}
              />
            </fieldset>
            <Button className="button-submit" disabled={!formValid} onClick={onSubmit}>
              Submit feedback
            </Button>
          </>
        )}
      </ModalBody>
    </ComposedModal>,
    document.body,
  );
};

export { FeedbackDialog };
