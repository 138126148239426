import { getWellsGeometry } from '@whitespace/app-3dviewer';
import { BufferGeometry } from 'three';
import { GLOBAL_SCALE } from './TargetsLoader';
import { getData } from '../utils/getData';
import { useApplicationContext } from '../context/ApplicationContext';

export interface ManualWellsImportData {
  azi: number[];
  coords: number[][];
  dls: string[];
  id: number;
  incs: number[];
  md: number[];
  name: string;
}

export interface ManualWellsData {
  title: string;
  geometry: BufferGeometry;
  wells: ManualWellsImportData[];
}

export const useManualWellsLoader = () => {
  const { field, objectsOffset, setManualWellsData } = useApplicationContext();
  const fetchManualWellsData = async () => {
    const response = await getData<ManualWellsImportData[]>(`/meshes/${field}/existing_wells.json`);

    const wellsData: ManualWellsData = {
      title: 'Existing wells',
      wells: response.data,
      geometry: getWellsGeometry(response.data as any, GLOBAL_SCALE, objectsOffset!),
    };
    setManualWellsData(wellsData);
  };

  return {
    fetchManualWellsData,
  };
};
