import * as React from 'react';
import { Accordion, AccordionItem, Button, Checkbox, Dropdown } from 'carbon-components-react';
import update from 'immutability-helper';
import { useDropzone } from 'react-dropzone';

import { RangeSlider } from '../organisms/RangeSlider';
import { Field, useApplicationContext } from '../../context/ApplicationContext';
import { TargetDataImport, useTargetsLoader } from '../../hooks/TargetsLoader';
import { WellInfo } from './WellInfo';

import css from './Targets.module.scss';
import { useConstrainedTargetData } from 'hooks/ConstrainedTargetData';
import { exportWells } from 'utils/wellExport';
import { TargetWell } from 'types/Targets';
import { LoadingTimeWarningDialog } from 'components/organisms/LoadingTimeWarningDialog';

const Targets = React.memo(() => {
  const [showLongLoadingTimeWarning, setShowLongLoadingTimeWarning] = React.useState(false);
  const {
    field,
    targets,
    targetsData,
    setTargetsData,
    setTargets,
    selectedTargets,
    setSelectedWell,
    setEditWell,
    setSelectedTargets,
    constraints,
    setConstraints,
    constraintRanges,
    getSelectedWell,
    getSelectedTarget,

    allowReroutingAccessRoads,
    setAllowReroutingAccessRoads,
    allowReroutingCathodicFeatures,
    setAllowReroutingCathodicFeatures,
    allowReroutingBuriedPowerlines,
    setAllowReroutingBuriedPowerlines,
    onlyOutsidePolymerInjectionArea,
    setOnlyOutsidePolymerInjectionArea,

    objectsOffset,
  } = useApplicationContext();
  const { convertData } = useTargetsLoader();
  const wellData = getSelectedWell();
  const selectedTarget = getSelectedTarget();

  const handleDrop = (acceptedFiles: File[]) => {
    setEditWell(null);
    setSelectedWell(null);

    if (acceptedFiles.length === 0) {
      return;
    }

    const file = acceptedFiles[0];

    if (!file.name.endsWith('.json')) {
      return;
    }

    file.arrayBuffer().then((result) => {
      const targetData = JSON.parse(new TextDecoder().decode(result)) as TargetDataImport[];

      if (!Array.isArray(targetData)) {
        return;
      }

      const name = `Uploaded_${targetsData.length + 1}`;
      const uploadedTarget = convertData(name, targetData, objectsOffset!, targetsData.length + 1);

      setTargetsData([...targetsData, uploadedTarget]);
      setTargets([...targets, name]);
    });
  };

  const { getRootProps } = useDropzone({ onDrop: handleDrop });

  const toggleAllowReroutingAccessRoads = () => {
    setAllowReroutingAccessRoads(!allowReroutingAccessRoads);
  };
  const toggleAllowReroutingCathodicFeatures = () => {
    setAllowReroutingCathodicFeatures(!allowReroutingCathodicFeatures);
  };
  const toggleAllowReroutingBuriedPowerlines = () => {
    setAllowReroutingBuriedPowerlines(!allowReroutingBuriedPowerlines);
  };

  const handleSliderChange = (value: number, id: string): void => {
    setConstraints(update(constraints, { [id]: { $set: value } }));
  };

  React.useEffect(() => {
    setSelectedTargets([]);
    setSelectedWell(null);
    setEditWell(null);
  }, [field]);

  const handleSelect = (data: { selectedItem: string }) => {
    if (!data.selectedItem) {
      setSelectedTargets([]);
      setSelectedWell(null);
      setEditWell(null);
    } else {
      if (data.selectedItem === 'All') {
        setShowLongLoadingTimeWarning(true);
        return;
      } else {
        setSelectedTargets([data.selectedItem]);
      }
      setSelectedWell(null);
      setEditWell(null);
    }
  };

  const handleShowAllWells = () => {
    setSelectedTargets(targets);
    setSelectedWell(null);
    setEditWell(null);
  };

  const { constrainedSelectedTargetsData } = useConstrainedTargetData();

  const handleExportVisibleWells = () => {
    const wells = constrainedSelectedTargetsData
      .flatMap((target) => target.filteredWells)
      .filter((well) => well !== undefined) as TargetWell[];

    if (wells.length === 0) {
      return;
    }

    exportWells(wells, 'visible');
  };

  const exportVisibleWellsDisabled = React.useMemo(
    () =>
      constrainedSelectedTargetsData.flatMap((target) => target.filteredWells).filter((well) => well !== undefined)
        .length === 0,
    [constrainedSelectedTargetsData],
  );

  return (
    <div className={css.root} {...getRootProps()}>
      <Dropdown<string>
        className="dark"
        ariaLabel="Dropdown"
        id="target-selected"
        items={['All', ...targets]}
        label="Select target"
        titleText=""
        selectedItem={selectedTargets.length > 1 ? 'All' : selectedTargets.length > 0 ? selectedTargets[0] : ''}
        onChange={handleSelect}
      />

      <Accordion className="dark">
        <AccordionItem title="Surface location constraints">
          <RangeSlider
            title="Surface easting"
            id="easting"
            max={Math.ceil(constraintRanges.easting.max)}
            min={Math.floor(constraintRanges.easting.min)}
            step={0.1}
            value={constraints.easting}
            inverted={false}
            setValue={handleSliderChange}
            longValues
          />
          <RangeSlider
            title="Surface northing"
            id="northing"
            max={Math.ceil(constraintRanges.northing.max)}
            min={Math.floor(constraintRanges.northing.min)}
            step={0.1}
            value={constraints.northing}
            inverted={false}
            setValue={handleSliderChange}
            longValues
          />
          {field !== Field.NIMR_E_INJ && (
            <>
              <Checkbox
                className="dark"
                id="allow-rerouting-access-roads"
                labelText="Allow rerouting of access roads"
                checked={allowReroutingAccessRoads}
                onChange={toggleAllowReroutingAccessRoads}
              />
              <Checkbox
                className="dark"
                id="allow-rerouting-cathodic-features"
                labelText="Allow rerouting of cathodic features"
                checked={allowReroutingCathodicFeatures}
                onChange={toggleAllowReroutingCathodicFeatures}
              />
              <Checkbox
                className="dark"
                id="allow-rerouting-buried-powerlines"
                labelText="Allow rerouting of buried powerlines"
                checked={allowReroutingBuriedPowerlines}
                onChange={toggleAllowReroutingBuriedPowerlines}
              />
            </>
          )}
        </AccordionItem>

        <AccordionItem title="Well constraints">
          <RangeSlider
            title="Well cost (mln USD)"
            id="wellCost"
            max={Math.ceil(constraintRanges.wellCost.max / 1_000_000)}
            min={Math.floor(constraintRanges.wellCost.min / 1_000_000)}
            step={0.05}
            value={constraints.wellCost / 1_000_000}
            inverted={false}
            setValue={(value, id) => handleSliderChange(value * 1_000_000, id)}
            minMaxDecimals={2}
            valueDecimals={2}
          />
          <RangeSlider
            title="Drain length (m)"
            id="drainLength"
            max={Math.ceil(constraintRanges.drainLength.max)}
            min={Math.floor(constraintRanges.drainLength.min)}
            step={0.1}
            value={constraints.drainLength}
            inverted={false}
            setValue={handleSliderChange}
          />
          <RangeSlider
            title="Well length (m)"
            id="wellLength"
            max={Math.ceil(constraintRanges.wellLength.max)}
            min={Math.floor(constraintRanges.wellLength.min)}
            step={0.1}
            value={constraints.wellLength}
            inverted={false}
            setValue={handleSliderChange}
          />
          <RangeSlider
            title="Minimum c-c distance overburden (m)"
            id="minCCDistanceOverburden"
            max={Math.ceil(constraintRanges.minCCDistanceOverburden.max)}
            min={Math.floor(constraintRanges.minCCDistanceOverburden.min)}
            step={0.1}
            value={constraints.minCCDistanceOverburden}
            inverted={false}
            setValue={handleSliderChange}
          />
          <RangeSlider
            title="Minimum c-c distance drain (m)"
            id="minCCDistanceDrain"
            max={Math.ceil(constraintRanges.minCCDistanceDrain.max)}
            min={Math.floor(constraintRanges.minCCDistanceDrain.min)}
            step={0.1}
            value={constraints.minCCDistanceDrain}
            inverted={false}
            setValue={handleSliderChange}
          />
          <RangeSlider
            title="Outstep (m)"
            id="outstep"
            max={Math.ceil(constraintRanges.outstep.max)}
            min={Math.floor(constraintRanges.outstep.min)}
            step={0.1}
            value={constraints.outstep}
            inverted={false}
            setValue={handleSliderChange}
          />
          {field === Field.NIMR_E_INJ ? (
            <RangeSlider
              title="Max DLS (deg/30m)"
              id="maxDlsShallowestSection"
              max={Math.ceil(constraintRanges.maxDlsShallowestSection.max)}
              min={Math.floor(constraintRanges.maxDlsShallowestSection.min)}
              step={0.1}
              value={constraints.maxDlsShallowestSection}
              inverted={false}
              setValue={handleSliderChange}
            />
          ) : (
            <>
              <RangeSlider
                title="Max DLS shallowest section (deg/30m)"
                id="maxDlsShallowestSection"
                max={Math.ceil(constraintRanges.maxDlsShallowestSection.max)}
                min={Math.floor(constraintRanges.maxDlsShallowestSection.min)}
                step={0.1}
                value={constraints.maxDlsShallowestSection}
                inverted={false}
                setValue={handleSliderChange}
              />
              <RangeSlider
                title="Max DLS deepest section (deg/30m)"
                id="maxDlsDeepestSection"
                max={Math.ceil(constraintRanges.maxDlsDeepestSection.max)}
                min={Math.floor(constraintRanges.maxDlsDeepestSection.min)}
                step={0.1}
                value={constraints.maxDlsDeepestSection}
                inverted={false}
                setValue={handleSliderChange}
              />
            </>
          )}
          <RangeSlider
            title="Max azimuthal change (deg)"
            id="maxAzimuthalChange"
            max={Math.ceil(constraintRanges.maxAzimuthalChange.max)}
            min={Math.floor(constraintRanges.maxAzimuthalChange.min)}
            step={0.1}
            value={constraints.maxAzimuthalChange}
            inverted={false}
            setValue={handleSliderChange}
          />
          <RangeSlider
            title="Kick off depth (m)"
            id="kickOffDepth"
            max={Math.ceil(constraintRanges.kickOffDepth.max)}
            min={Math.floor(constraintRanges.kickOffDepth.min)}
            step={0.1}
            value={constraints.kickOffDepth}
            inverted={false}
            setValue={handleSliderChange}
          />
          <Checkbox
            className="dark"
            id="only-outside-polymer-injection-area"
            labelText="Only outside polymer injection area"
            checked={onlyOutsidePolymerInjectionArea}
            onChange={setOnlyOutsidePolymerInjectionArea}
          />
        </AccordionItem>
        {wellData && (
          <AccordionItem title="Well info" open>
            <WellInfo />
          </AccordionItem>
        )}
      </Accordion>
      <Button
        className={css.exportVisibleWells}
        disabled={exportVisibleWellsDisabled}
        size="sm"
        onClick={handleExportVisibleWells}
      >
        Export visible wells
      </Button>

      <LoadingTimeWarningDialog
        open={showLongLoadingTimeWarning}
        setOpen={setShowLongLoadingTimeWarning}
        onAccept={handleShowAllWells}
      />
    </div>
  );
});

Targets.displayName = 'Targets';

export { Targets };
