import { Ranges } from '../types/Ranges';

const CROP_MARGIN = 100;

export const getRanges = (coordinates: Array<number[][]>): Ranges => {
  const initial: Ranges = {
    x: {
      min: Infinity,
      max: 0,
    },
    y: {
      min: Infinity,
      max: 0,
    },
    z: {
      min: Infinity,
      max: 0,
    },
  };

  const ranges = coordinates.reduce<Ranges>(
    (accumulator: Ranges, next: number[][]) =>
      next.reduce<Ranges>((subAccumulator: Ranges, coordinate: number[]) => {
        const range: Ranges = {
          x: {
            min: Math.min(subAccumulator.x.min, coordinate[0]),
            max: Math.max(subAccumulator.x.max, coordinate[0]),
          },
          y: {
            min: Math.min(subAccumulator.y.min, coordinate[1]),
            max: Math.max(subAccumulator.y.max, coordinate[1]),
          },
        };

        if (coordinate.length >= 3) {
          range.z = {
            min: Math.min(subAccumulator.z!.min, coordinate[2]),
            max: Math.max(subAccumulator.z!.max, coordinate[2]),
          };
        }

        return range;
      }, accumulator),
    initial,
  );

  return ranges;
};

export const reverseCoordinate = (coordinate: number[], ranges: Ranges): number[] => [
  coordinate[0] + ranges.x.min,
  ranges.y.max - coordinate[1],
];

export const convertCoordinateData = (coordinates: number[][], ranges: Ranges) =>
  coordinates.map((coordinate) => {
    const data = [coordinate[0] - ranges.x.min, ranges.y.max - coordinate[1]];

    if (coordinate.length >= 3) {
      data.push(coordinate[2]);
    }

    return data;
  });

export const cropData = (coordinates: number[][], ranges: Ranges) => {
  const squareRange: Ranges = {
    x: {
      min: Math.min(ranges.x.min, ranges.y.min),
      max: Math.max(ranges.x.max, ranges.y.max),
    },
    y: {
      min: Math.min(ranges.x.min, ranges.y.min),
      max: Math.max(ranges.x.max, ranges.y.max),
    },
  };

  const result = coordinates.filter(
    (coordinate: number[]) =>
      coordinate[0] >= squareRange.x.min - CROP_MARGIN
      && coordinate[0] <= squareRange.x.max + CROP_MARGIN
      && coordinate[1] >= squareRange.y.min - CROP_MARGIN
      && coordinate[1] <= squareRange.y.max + CROP_MARGIN,
  );

  return result;
};
