import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow,
} from 'carbon-components-react';

import { useApplicationContext } from '../../context/ApplicationContext';
import { RangeDisplay } from '../organisms/RangeDisplay';
import { useSetRanges } from '../../hooks/SetRanges';
import StatusIcon, { IconStatus } from '../atoms/StatusIcon';
import { Target } from '../../types/Targets';

import css from './AllWells.module.scss';

const AllWells = React.memo(() => {
  const history = useHistory();

  const {
    targetsData, campaignWells, setSelectedTargets, setSelectedWell, setEditWell,
  } = useApplicationContext();
  const { getRanges } = useSetRanges();

  const handleClick = (target: Target) => () => {
    setSelectedTargets([target.title]);
    setSelectedWell(null);
    setEditWell(null);

    history.push('/targets-view');
  };

  const ranges = React.useMemo(() => getRanges(targetsData), [targetsData]);

  return (
    <div className={css.root}>
      <TableContainer>
        <Table size="short">
          <TableHead>
            <TableRow>
              <TableHeader>Target</TableHeader>
              <TableHeader>Wells found</TableHeader>
              <TableHeader>Well cost</TableHeader>
              <TableHeader>Max dls shallowest</TableHeader>
              <TableHeader>Max dls deepest</TableHeader>
              <TableHeader>Length</TableHeader>
              <TableHeader>Azi change</TableHeader>
              <TableHeader>Drain length</TableHeader>
              <TableHeader>Outstep</TableHeader>
              <TableHeader>In current campaign?</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {targetsData.map((target) => {
              const targetRanges = getRanges([target], true);

              const isInCampaign = campaignWells.some((well) => well.target === target.title);

              return (
                <TableRow key={target.title} onClick={handleClick(target)}>
                  <TableCell className={css.target}>{target.title}</TableCell>
                  <TableCell>{target.wells.length}</TableCell>
                  <TableCell>
                    {/* <RangeDisplay
                      rangeMinTip={`Minimum, all wells: ${Math.round(ranges.surfaceInc.min)} deg`}
                      rangeMaxTip={`Maximum all wells: ${Math.round(ranges.surfaceInc.max)} deg`}
                      valueTip={`Minimum, this target: ${Math.round(targetRanges.surfaceInc.min)} deg`}
                      valueMaxTip={`Maximum, this target: ${Math.round(targetRanges.surfaceInc.max)} deg`}
                      rangeMin={ranges.surfaceInc.min}
                      rangeMax={ranges.surfaceInc.max}
                      value={targetRanges.surfaceInc.min}
                      valueMax={targetRanges.surfaceInc.max}
                      width={125}
                    /> */}
                  </TableCell>
                  <TableCell>
                    <RangeDisplay
                      rangeMinTip={`Minimum, all wells: ${ranges.maxDlsShallowestSection.min}`}
                      rangeMaxTip={`Maximum all wells: ${ranges.maxDlsShallowestSection.max}`}
                      valueTip={`Minimum, this target: ${targetRanges.maxDlsShallowestSection.min}`}
                      valueMaxTip={`Maximum, this target: ${targetRanges.maxDlsShallowestSection.max}`}
                      rangeMin={ranges.maxDlsShallowestSection.min}
                      rangeMax={ranges.maxDlsShallowestSection.max}
                      value={targetRanges.maxDlsShallowestSection.min}
                      valueMax={targetRanges.maxDlsShallowestSection.max}
                      width={125}
                    />
                  </TableCell>
                  <TableCell>
                    <RangeDisplay
                      rangeMinTip={`Minimum, all wells: ${ranges.maxDlsDeepestSection.min}`}
                      rangeMaxTip={`Maximum all wells: ${ranges.maxDlsDeepestSection.max}`}
                      valueTip={`Minimum, this target: ${targetRanges.maxDlsDeepestSection.min}`}
                      valueMaxTip={`Maximum, this target: ${targetRanges.maxDlsDeepestSection.max}`}
                      rangeMin={ranges.maxDlsDeepestSection.min}
                      rangeMax={ranges.maxDlsDeepestSection.max}
                      value={targetRanges.maxDlsDeepestSection.min}
                      valueMax={targetRanges.maxDlsDeepestSection.max}
                      width={125}
                    />
                  </TableCell>
                  <TableCell>
                    {/* <RangeDisplay
                      rangeMinTip={`Minimum, all wells: ${ranges.maxMD.min} m`}
                      rangeMaxTip={`Maximum all wells: ${ranges.maxMD.max} m`}
                      valueTip={`Minimum, this target: ${targetRanges.maxMD.min} m`}
                      valueMaxTip={`Maximum, this target: ${targetRanges.maxMD.max} m`}
                      rangeMin={ranges.maxMD.min}
                      rangeMax={ranges.maxMD.max}
                      value={targetRanges.maxMD.min}
                      valueMax={targetRanges.maxMD.max}
                      width={125}
                    /> */}
                  </TableCell>
                  <TableCell>
                    {/* <RangeDisplay
                      rangeMinTip={`Minimum, all wells: ${ranges.azimuthChange.min} deg`}
                      rangeMaxTip={`Maximum all wells: ${ranges.azimuthChange.max} deg`}
                      valueTip={`Minimum, this target: ${targetRanges.azimuthChange.min} deg`}
                      valueMaxTip={`Maximum, this target: ${targetRanges.azimuthChange.max} deg`}
                      rangeMin={ranges.azimuthChange.min}
                      rangeMax={ranges.azimuthChange.max}
                      value={targetRanges.azimuthChange.min}
                      valueMax={targetRanges.azimuthChange.max}
                      width={125}
                    /> */}
                  </TableCell> 
                  <TableCell>
                    {/* <StatusIcon status={hasDualSlotted ? IconStatus.completed : IconStatus.misuse} /> */}
                  </TableCell>
                  <TableCell>
                    {/* <StatusIcon
                      status={
                        target.flippedWellCount > 0 && target.wellCount > 0 ? IconStatus.completed : IconStatus.misuse
                      }
                    /> */}
                  </TableCell>
                  <TableCell>
                    <StatusIcon status={isInCampaign ? IconStatus.completed : IconStatus.misuse} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
});

AllWells.displayName = 'AllWellsData';

export { AllWells };
