import * as React from 'react';

import { TargetMapContent } from '../molecules/TargetMapContent';

import css from './TargetMap.module.scss';

export interface MapData {
  scale: number;
  x: number;
  y: number;
}

interface TargetMapProps {
  tileTrigger: boolean;
}

const TargetMap = React.memo<TargetMapProps>((props) => {
  const { tileTrigger } = props;
  const elementRef = React.useRef<HTMLDivElement>(null);
  const [setColors] = React.useState<Array<{ id: number; color: string }>>([]);

  return (
    <div ref={elementRef} className={css.root}>
      <TargetMapContent setColors={setColors} tileTrigger={tileTrigger} />
    </div>
  );
});

TargetMap.displayName = 'TargetMap';

export { TargetMap };
