import axios from 'axios';

import { getReactAppAPIURL } from '../../utils/getWindowConfigOption';
import { User } from './Login';

interface MakeLoginRequest {
  (
    user: User,
    loggedIn: (isLoggedIn: boolean) => void,
    setError: (error: boolean) => void,
    setLoading: (isLoading: boolean) => void,
  ): void;
}

const makeLoginRequest: MakeLoginRequest = (
  user,
  loggedIn,
  setError,
  setLoading,
) => {
  const url = `${getReactAppAPIURL()}/login`;
  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const data = new FormData();

  data.append('username', user.username);
  data.append('password', user.password);

  axios
    .post(url, data, config)
    .then((response) => {
      setLoading(false);

      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        loggedIn(true);
      }
    })
    .catch((error) => {
      setLoading(false);
      setError(
        error.response
          ? error.response.data.detail
          : 'Server seems to be offline',
      );
    });
};

export { makeLoginRequest };
