import * as React from 'react';
import { Button, TooltipIcon } from 'carbon-components-react';

import { Save16, Delete16, WatsonHealthSaveAnnotation16 } from '@carbon/icons-react';

import { CardLabel } from '../atoms/CardLabel';
import { RawData, useApplicationContext } from '../../context/ApplicationContext';
import { TargetWell } from '../../types/Targets';
import { useCreateCampaign, useFetchCampaigns, useRemoveCampaign, useUpdateCampaign } from '../../hooks/campaign';
import { useNegativeTriggerEffect } from '../../hooks/NegativeTriggeredEffect';
import { AcknowledgementModal } from './AcknowledgemnetModal';
import { CampaignNameDialog } from '../organisms/CampaignNameDialog';
import { CampaignInfoTable } from '../organisms/CampaignInfoTable';

import css from './CampaignInfo.module.scss';
import { exportWells } from 'utils/wellExport';

export enum CampaignSorting {
  TargetAsc,
  TargetDesc,
  PropertyAsc,
  PropertyDesc,
}

export enum CampaignProperties {
  MaxDlsShallowestSection,
  MaxDlsDeepestSection,
  MaxSurface,
  AzimuthChange,
  MaxDM,
}

const CampaignInfo = React.memo(() => {
  const { isRemoving, removeError, removeCampaign } = useRemoveCampaign();
  const { isFetching, refreshList, fetchError } = useFetchCampaigns();
  const { isUpdating, updateError, updateCampaign } = useUpdateCampaign();
  const { isCreating, createCampaign, createError } = useCreateCampaign();
  const [isRemoveOpen, setRemoveOpen] = React.useState<boolean>(false);
  const [isCopyOpen, setCopyOpen] = React.useState<boolean>(false);
  const [sorting, setSorting] = React.useState<CampaignSorting>(CampaignSorting.TargetAsc);
  const [property, setProperty] = React.useState<CampaignProperties>(CampaignProperties.MaxDlsShallowestSection);

  const {
    campaignWells,
    setCampaignWells,
    setNewNotification,
    selectedCampaign,
    setSelectedCampaign,
  } = useApplicationContext();

  const handleRemoveFromCampaign = (campaignWell: RawData) => () => {
    const wells = campaignWells.filter((well) => well !== campaignWell);

    setNewNotification('Well removed from campaign');

    setCampaignWells(wells);

    const wellList = wells.map((well: TargetWell) => ({
      target_name: well.area,
      well_id: well.wellId,
    }));

    updateCampaign(selectedCampaign!.id!, selectedCampaign!.name, wellList);
  };

  const isPropertySorting = (): boolean =>
    sorting === CampaignSorting.PropertyDesc || sorting === CampaignSorting.PropertyAsc;

  const sorted = campaignWells.sort((a, b) => {
    if (!isPropertySorting()) {
      if (sorting === CampaignSorting.TargetDesc) {
        return b.area!.localeCompare(a.area!);
      }

      return a.area!.localeCompare(b.area!);
    }

    if (isPropertySorting() && property === CampaignProperties.MaxDlsShallowestSection) {
      return sorting === CampaignSorting.PropertyDesc ? a.maxDlsShallowestSection - b.maxDlsShallowestSection : b.maxDlsShallowestSection - a.maxDlsShallowestSection;
    }

    if (isPropertySorting() && property === CampaignProperties.MaxDlsDeepestSection) {
      return sorting === CampaignSorting.PropertyDesc ? a.maxDlsDeepestSection - b.maxDlsDeepestSection : b.maxDlsDeepestSection - a.maxDlsDeepestSection;
    }

    if (isPropertySorting() && property === CampaignProperties.MaxSurface) {
      return sorting === CampaignSorting.PropertyDesc
        ? a.maxSurfaceDip - b.maxSurfaceDip
        : b.maxSurfaceDip - a.maxSurfaceDip;
    }

    if (isPropertySorting() && property === CampaignProperties.MaxDM) {
      return sorting === CampaignSorting.PropertyDesc ? a.maxMD - b.maxMD : b.maxMD - a.maxMD;
    }

    return sorting === CampaignSorting.PropertyDesc
      ? a.azimuthChange - b.azimuthChange
      : b.azimuthChange - a.azimuthChange;
  });

  const handleExport = async () => {
    exportWells(sorted, 'campaign')
  };

  const handleCopyCampaign = () => {
    setCopyOpen(true);
  };

  const handleUpdateCampaign = () => {
    const wellList = campaignWells.map((well: TargetWell) => ({
      target_name: well.area,
      well_id: well.wellId,
    }));

    updateCampaign(selectedCampaign!.id!, selectedCampaign!.name, wellList);
  };

  const handleCreateCampaign = (name: string) => {
    const wellList = campaignWells.map((well: TargetWell) => ({
      target_name: well.area,
      well_id: well.wellId,
    }));

    createCampaign(name, wellList);
  };

  useNegativeTriggerEffect(() => {
    if (updateError) {
      setNewNotification('Error saved campaign', 'error');
    } else {
      setNewNotification('Campaign has been saved');
      refreshList();
    }
  }, isUpdating);

  useNegativeTriggerEffect(() => {
    if (createError) {
      setNewNotification('Error copying campaign', 'error');
    } else {
      setNewNotification('Campaign has been copied');
      refreshList();
    }
  }, isCreating);

  const handleRemoveCampaign = () => {
    setRemoveOpen(true);
  };

  const performRemoveCampaign = () => {
    removeCampaign(selectedCampaign!.id!);
  };

  useNegativeTriggerEffect(() => {
    if (removeError) {
      setNewNotification('Error removing campaign', 'error');
    } else {
      setNewNotification('Campaign has been removed');
      setSelectedCampaign(null);
      setCampaignWells([]);
      refreshList();
    }
  }, isRemoving);

  useNegativeTriggerEffect(() => {
    if (fetchError) {
      setNewNotification('Error fetching campaign list', 'error');
    }
  }, isFetching);

  const getLabel = () => {
    if (!selectedCampaign) {
      return null;
    }

    return (
      <div className={css.buttonLabel}>
        <div></div>
        <Button className={css.button} onClick={handleUpdateCampaign} hasIconOnly>
          <TooltipIcon tooltipText="Save current campaign">
            <Save16 />
          </TooltipIcon>
        </Button>
        <Button className={css.button} onClick={handleCopyCampaign} hasIconOnly>
          <TooltipIcon tooltipText="Save campaign as...">
            <WatsonHealthSaveAnnotation16 />
          </TooltipIcon>
        </Button>
        <Button className={css.deleteButton} onClick={handleRemoveCampaign} hasIconOnly>
          <TooltipIcon tooltipText="Delete current campaign">
            <Delete16 />
          </TooltipIcon>
        </Button>
      </div>
    );
  };

  return (
    <div className={css.root}>
      <CardLabel text={getLabel()} campaignTitle />

      <CampaignInfoTable
        onRemoveFromCampaign={handleRemoveFromCampaign}
        setSorting={setSorting}
        property={property}
        setProperty={setProperty}
        campaignWells={sorted}
      />
      {sorted.length > 0 && <Button onClick={handleExport}>Export campaign</Button>}

      <AcknowledgementModal
        message="This will remove the campaign"
        open={isRemoveOpen}
        setOpen={setRemoveOpen}
        onAccept={performRemoveCampaign}
      />
      <CampaignNameDialog
        message="This will create a copy of the current campaign"
        open={isCopyOpen}
        setOpen={setCopyOpen}
        onAccept={handleCreateCampaign}
      />
    </div>
  );
});

CampaignInfo.displayName = 'CampaignInfo';

export { CampaignInfo };
