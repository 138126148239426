import * as React from 'react';
import classNames from 'classnames';

import { ExpandIcon } from '../atoms/ExpandIcon';

import css from './Tile.module.scss';

interface TileProps {
  className?: string;
  expandedClass?: string;
  children: React.ReactNode;
  name?: string;
  collapse?: string;
  expanded: string;
  onSetExpanded?: (name: string) => void;
  canExpand?: boolean;
  iconColor?: string;
  expandIconInverse?: boolean;
}

const Tile: React.FC<TileProps> = (props): React.ReactElement => {
  const {
    children,
    className,
    expandedClass,
    onSetExpanded = null,
    name = '',
    canExpand = true,
    iconColor,
    expanded,
    collapse = '',
    expandIconInverse = false,
  } = props;

  return (
    <div className={classNames(css.root, className, expandedClass)}>
      {children}
      {onSetExpanded && canExpand && (
        <ExpandIcon
          expanded={expanded}
          name={name}
          collapse={collapse}
          onClickHandle={onSetExpanded}
          iconColor={iconColor}
          inverse={expandIconInverse}
        />
      )}
    </div>
  );
};

export { Tile };
