import { ConstraintRanges, useApplicationContext } from '../context/ApplicationContext';
import { Target } from '../types/Targets';
import { round, floor, ceil } from 'lodash';

export const useSetRanges = () => {
  const { targetsData, setConstraintRanges, setConstraints, selectedTargets } = useApplicationContext();

  const initialRanges: ConstraintRanges = {
    easting: {
      min: Infinity,
      max: 0,
    },
    northing: {
      min: Infinity,
      max: 0,
    },
    wellCost: {
      min: Infinity,
      max: 0,
    },
    drainLength: {
      min: Infinity,
      max: 0,
    },
    wellLength: {
      min: Infinity,
      max: 0,
    },
    minCCDistanceOverburden: {
      min: Infinity,
      max: 0,
    },
    minCCDistanceDrain: {
      min: Infinity,
      max: 0,
    },
    outstep: {
      min: Infinity,
      max: 0,
    },
    maxDlsShallowestSection: {
      min: Infinity,
      max: 0,
    },
    maxDlsDeepestSection: {
      min: Infinity,
      max: 0,
    },
    maxAzimuthalChange: {
      min: Infinity,
      max: 0,
    },
    kickOffDepth: {
      min: Infinity,
      max: 0,
    },
  };

  const getRanges = (targets: Partial<Target>[], roundOrClamp = false, decimals = 1) => {
    const roundValue = (value: number, clampDecimals: number = 2) => {
      return [
        roundOrClamp ? round(value, decimals) : floor(value, clampDecimals),
        roundOrClamp ? round(value, decimals) : ceil(value, clampDecimals),
      ];
    };

    const ranges = targets.reduce<ConstraintRanges>(
      (rangesByTarget, nextTarget) =>
        nextTarget.wells!.reduce<ConstraintRanges>((rangesByWell, nextWell) => {
          const updatedRanges = {
            ...rangesByWell,
          };

          const [minEasting, maxEasting] = roundValue(nextWell.easting);
          const [minNorthing, maxNorthing] = roundValue(nextWell.northing);
          const [minWellCost, maxWellCost] = roundValue(nextWell.wellCost);
          const [minDrainLength, maxDrainLength] = roundValue(nextWell.drainLength);
          const [minWellLength, maxWellLength] = roundValue(nextWell.wellLength);
          const [minCCDistanceOverburden, maxCCDistanceOverburden] = roundValue(nextWell.minCCDistanceOverburden);
          const [minCCDistanceDrain, maxCCDistanceDrain] = roundValue(nextWell.minCCDistanceDrain);
          const [minOutstep, maxOutstep] = roundValue(nextWell.outstep);
          const [minDlsShallowestSection, maxDlsShallowestSection] = roundValue(nextWell.maxDlsShallowestSection);
          const [minDlsDeepestSection, maxDlsDeepestSection] = roundValue(nextWell.maxDlsDeepestSection);
          const [minAzimuthalChange, maxAzimuthalChange] = roundValue(nextWell.maxAzimuthalChange);
          const [minKickOffDepth, maxKickOffDepth] = roundValue(nextWell.kickOffDepth);

          return {
            easting: {
              min: Math.min(updatedRanges.easting.min, minEasting),
              max: Math.max(updatedRanges.easting.max, maxEasting),
            },
            northing: {
              min: Math.min(updatedRanges.northing.min, minNorthing),
              max: Math.max(updatedRanges.northing.max, maxNorthing),
            },
            wellCost: {
              min: Math.min(updatedRanges.wellCost.min, minWellCost),
              max: Math.max(updatedRanges.wellCost.max, maxWellCost),
            },
            drainLength: {
              min: Math.min(updatedRanges.drainLength.min, minDrainLength),
              max: Math.max(updatedRanges.drainLength.max, maxDrainLength),
            },
            wellLength: {
              min: Math.min(updatedRanges.wellLength.min, minWellLength),
              max: Math.max(updatedRanges.wellLength.max, maxWellLength),
            },
            minCCDistanceOverburden: {
              min: Math.min(updatedRanges.minCCDistanceOverburden.min, minCCDistanceOverburden),
              max: Math.max(updatedRanges.minCCDistanceOverburden.max, maxCCDistanceOverburden),
            },
            minCCDistanceDrain: {
              min: Math.min(updatedRanges.minCCDistanceDrain.min, minCCDistanceDrain),
              max: Math.max(updatedRanges.minCCDistanceDrain.max, maxCCDistanceDrain),
            },
            outstep: {
              min: Math.min(updatedRanges.outstep.min, minOutstep),
              max: Math.max(updatedRanges.outstep.max, maxOutstep),
            },
            maxDlsShallowestSection: {
              min: Math.min(updatedRanges.maxDlsShallowestSection.min, minDlsShallowestSection),
              max: Math.max(updatedRanges.maxDlsShallowestSection.max, maxDlsShallowestSection),
            },
            maxDlsDeepestSection: {
              min: Math.min(updatedRanges.maxDlsDeepestSection.min, minDlsDeepestSection),
              max: Math.max(updatedRanges.maxDlsDeepestSection.max, maxDlsDeepestSection),
            },
            maxAzimuthalChange: {
              min: Math.min(updatedRanges.maxAzimuthalChange.min, minAzimuthalChange),
              max: Math.max(updatedRanges.maxAzimuthalChange.max, maxAzimuthalChange),
            },
            kickOffDepth: {
              min: Math.min(updatedRanges.kickOffDepth.min, minKickOffDepth),
              max: Math.max(updatedRanges.kickOffDepth.max, maxKickOffDepth),
            },
          };
        }, rangesByTarget),
      initialRanges,
    );

    return ranges;
  };

  const setRanges = () => {
    const targets =
      selectedTargets.length === 0
        ? targetsData
        : targetsData.filter((findTarget) => selectedTargets.includes(findTarget.title));

    const ranges = getRanges(targets);

    setConstraintRanges(ranges);
    setConstraints({
      easting: ranges.easting.max,
      northing: ranges.northing.max,
      rerouteRoads: false,
      rerouteFlowLines: false,
      rerouteCables: false,
      wellCost: ranges.wellCost.max,
      drainLength: ranges.drainLength.max,
      wellLength: ranges.wellLength.max,
      minCCDistanceOverburden: ranges.minCCDistanceOverburden.max,
      minCCDistanceDrain: ranges.minCCDistanceDrain.max,
      outstep: ranges.outstep.max,
      maxDlsShallowestSection: ranges.maxDlsShallowestSection.max,
      maxDlsDeepestSection: ranges.maxDlsDeepestSection.max,
      maxAzimuthalChange: ranges.maxAzimuthalChange.max,
      kickOffDepth: ranges.kickOffDepth.max,
    });
  };

  return {
    setRanges,
    getRanges,
  };
};
