import * as React from 'react';
import { Grid } from 'carbon-components-react';
import classNames from 'classnames';

import { getWellsGeometry } from '@whitespace/app-3dviewer';
import { Viewport } from '../viewport/Viewport';
import { Tile } from '../organisms/Tile';

import { useApplicationContext } from '../../context/ApplicationContext';
import { GLOBAL_SCALE } from '../../hooks/TargetsLoader';
import { Target } from '../../types/Targets';
import { CampaignScatterPlot } from '../organisms/CampaignScatterPlot';
import { CampaignSummary } from '../organisms/CampaignSummary';

import css from './CampaignContent.module.scss';

const CampaignContent = React.memo(() => {
  const { campaignWells, objectsOffset } = useApplicationContext();
  const [expanded, setExpanded] = React.useState<string>('viewport');
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const gridRef = React.useRef<HTMLDivElement>(null);

  const target: Target = {
    fileName: 'Campaign',
    id: -1,
    wells: [...campaignWells],
    title: '',
    geometry: getWellsGeometry(campaignWells, GLOBAL_SCALE, objectsOffset!),
    wellCount: campaignWells.filter((campaignWell) => !campaignWell.flipped).length,
    flippedWellCount: campaignWells.filter((campaignWell) => campaignWell.flipped).length,
  };

  const handleSetExpanded = (name: string) => {
    setExpanded(name);
  };

  const handleResize = () => {
    if (gridRef.current) {
      const grid = gridRef.current.firstElementChild as HTMLDivElement;
      const gridBoundingClientRect = grid!.getBoundingClientRect();
      const gridWidth = gridBoundingClientRect.width;
      const mapTile = grid!.children[2] as HTMLDivElement;
      const boundingClientRect = mapTile.getBoundingClientRect();
      const width = gridWidth - boundingClientRect.height;

      if (expanded === 'map') {
        grid!.style.gridTemplateColumns = `${boundingClientRect.height}px 1fr `;
      } else {
        grid!.style.gridTemplateColumns = `${width - 3 * 16}px 1fr`;
      }
    }

    setTrigger(!trigger);
  };

  React.useEffect(() => {
    handleResize();

    setTrigger(!trigger);
  }, [expanded]);

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div className={css.root} ref={gridRef}>
      <Grid className={classNames(css.grid, expanded === 'map' ? css.gridMap : '')} fullWidth>
        <Tile
          expanded={expanded}
          expandedClass={expanded === 'map' ? css.viewportMap : ''}
          className={css.viewport}
          name="viewport"
          collapse="map"
          onSetExpanded={handleSetExpanded}
          iconColor="white"
          canExpand={false}
        >
          <Viewport trigger={trigger} targetData={campaignWells.length > 0 ? [target] : []} isCampaignView />
        </Tile>

        <Tile
          className={css.map}
          expanded={expanded}
          expandedClass={expanded === 'map' ? css.mapExpanded : ''}
          name="map"
          collapse="viewport"
          onSetExpanded={handleSetExpanded}
        >
          <CampaignSummary />
        </Tile>
        <Tile expanded={expanded} className={css.tile} name="someother" canExpand={false}>
          <CampaignScatterPlot trigger={trigger} />
        </Tile>
      </Grid>
    </div>
  );
});

CampaignContent.displayName = 'CampaignContent';

export { CampaignContent };
