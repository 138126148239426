import * as React from 'react';

import {
  CheckmarkFilled16,
  WarningAltFilled16,
  PauseOutlineFilled16,
  ErrorFilled16,
  CircleDash16,
  CheckmarkOutline16,
  Misuse16,
} from '@carbon/icons-react';
import { Loading } from 'carbon-components-react';

import css from './StatusIcon.module.scss';

export enum IconStatus {
  running,
  scheduled,
  completed,
  completedNonFilled,
  failed,
  notAllowed,
  notFound,
  misuse,
}

interface StatusIconProps {
  status: IconStatus;
}

const StatusIcon = React.memo<StatusIconProps>((props) => {
  const { status } = props;

  switch (status) {
    case IconStatus.running:
      return (
        <span>
          <Loading small withOverlay={false} description="" />
        </span>
      );
    case IconStatus.scheduled:
      return (
        <span className={css.iconScheduled}>
          <PauseOutlineFilled16 />
        </span>
      );
    case IconStatus.completed:
      return (
        <span className={css.iconCompleted}>
          <CheckmarkFilled16 />
        </span>
      );
    case IconStatus.completedNonFilled:
      return (
        <span>
          <CheckmarkOutline16 />
        </span>
      );
    case IconStatus.failed:
      return (
        <span className={css.iconFailed}>
          <WarningAltFilled16 />
        </span>
      );
    case IconStatus.notAllowed:
      return (
        <span className={css.iconNotAllowed}>
          <ErrorFilled16 />
        </span>
      );
    case IconStatus.notFound:
      return (
        <span>
          <CircleDash16 />
        </span>
      );
    case IconStatus.misuse:
      return (
        <span className={css.iconMisuse}>
          <Misuse16 />
        </span>
      );
    default:
      return <div />;
  }
});

export default StatusIcon;
