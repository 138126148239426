import * as React from 'react';
import { MouseEvent, SyntheticEvent } from 'react';
import {
  XYPlot, MarkSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, MarkSeriesPoint,
} from 'react-vis';

import { ChartData } from '../organisms/TargetScatterPlot';

import '../../../node_modules/react-vis/dist/style.css';
import css from './ConstraintScatterPlot.module.scss';

const TOOLTIP_OFFSET_X = -145;

interface ConstraintScatterPlotProps {
  data: ChartData[];
  maxX: number;
  minX: number;
  maxY: number;
  minY: number;
  xAxis: string;
  yAxis: string;
  xName: string;
  yName: string;
  width: number;
  height: number;
  selectedWellId?: number;
  onWellSelect?: (wellId: number | null) => void;
  onHoverWellSelect?: (wellId: number | null) => void;
  displayTooltip?: boolean;
}

const ConstraintScatterPlot = React.memo<ConstraintScatterPlotProps>((props) => {
  const {
    data,
    maxX,
    maxY,
    minX,
    minY,
    xAxis,
    yAxis,
    xName,
    yName,
    width,
    height,
    selectedWellId,
    onWellSelect,
    onHoverWellSelect,
    displayTooltip = false,
  } = props;

  const toolTipRef = React.useRef<HTMLDivElement>(null);

  const getColor = (item: ChartData) => {
    if (item.well.wellId === selectedWellId) {
      return '#F0C';
    }

    // if (item.hasDuplicateTarget) {
    //   return 'white';
    // }

    return '#AAA';
  };

  const filteredData = data.map((item) => ({
    x: item[xAxis],
    y: item[yAxis],
    size: 5,
    wellId: item.well.wellId,
    name: item.name,
    color: getColor(item),
  }));

  const handleClick = (point: MarkSeriesPoint) => {
    if (onWellSelect) {
      onWellSelect(point.wellId);
    }
  };

  const handleMouseOver = (point: MarkSeriesPoint, rvEvent: MouseEvent<HTMLElement>) => {
    const eventCopy: any = rvEvent;
    const syntheticEvent: SyntheticEvent = eventCopy.event;

    const rect = syntheticEvent.currentTarget.getBoundingClientRect();

    if (displayTooltip && toolTipRef.current) {
      toolTipRef.current.classList.add(css.toolTipVisible);
      toolTipRef.current.innerHTML = point.name;

      const isTooFar = rect.x + toolTipRef.current.clientWidth > window.innerWidth;
      const xOffset = isTooFar ? -toolTipRef.current.clientWidth : toolTipRef.current.clientWidth / -2;

      toolTipRef.current.style.left = `${rect.x + xOffset}px`;
      toolTipRef.current.style.top = `${rect.y + TOOLTIP_OFFSET_X}px`;
    }

    if (onHoverWellSelect) {
      onHoverWellSelect(point.wellId);
    }
  };

  const handleMouseOut = (point: MarkSeriesPoint) => {
    if (displayTooltip && toolTipRef.current) {
      toolTipRef.current.classList.remove(css.toolTipVisible);
    }

    if (onHoverWellSelect) {
      onHoverWellSelect(null);
    }
  };

  const parseValueX = (value: any, index: any, scale: any) => {
    const parsed = parseFloat(value);
    const fixed = parseFloat(parseFloat(value).toFixed(1));

    if (xAxis === 'maxMD') {
      if (scale.ticks().length > 6 && index % 2) {
        return '';
      }
    }

    if (parsed !== fixed) {
      return '';
    }

    return value;
  };

  const parseValueY = (value: any) => {
    const parsed = parseFloat(value);
    const fixed = parseFloat(parseFloat(value).toFixed(1));

    if (parsed !== fixed) {
      return '';
    }

    return value;
  };

  return (
    <div className={css.root}>
      <div ref={toolTipRef} className={css.toolTip}>
        <div />
      </div>
      <XYPlot height={height} width={width} xDomain={[minX, maxX]} yDomain={[minY, maxY]}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis title={xName} tickFormat={parseValueX as any} />
        <YAxis title={yName} tickFormat={parseValueY} />
        <MarkSeries
          colorType="literal"
          stroke="#AAA"
          data={filteredData}
          sizeRange={[5, 6]}
          onValueClick={handleClick}
          onValueMouseOver={handleMouseOver}
          onValueMouseOut={handleMouseOut}
        />
      </XYPlot>
    </div>
  );
});

ConstraintScatterPlot.displayName = 'ConstraintScatterPlot';

export { ConstraintScatterPlot };
