import React, {
  PropsWithChildren, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';

import css from './Tooltip.module.scss';

interface InfoTooltipProps {
  text?: string;
  className?: string;
  position?: 'left' | 'right' | 'bottom' | 'top';
}

const spacing = 6; // space between children and tooltip

// Tooltip that appears on hover
const Tooltip = (props: PropsWithChildren<InfoTooltipProps>) => {
  const {
    children, className, text, position = 'right',
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const [cssPos, setPosition] = useState<{ left: number; top: number; transform: string } | undefined>();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const { current } = ref;

    const mouseOver = () => {
      if (!current || !current.firstElementChild) {
        return;
      }

      const rect = current.firstElementChild.getBoundingClientRect();

      if (rect) {
        switch (position) {
          case 'left': {
            const { left } = rect;
            const top = rect.top + rect.height / 2;
            const transform = 'translateX(-100%) translateY(-50%)';

            setPosition({ left, top, transform });
            break;
          }
          case 'right': {
            const left = rect.right + spacing;
            const top = rect.top + rect.height / 2;
            const transform = 'translateY(-50%)';

            setPosition({ left, top, transform });
            break;
          }
          case 'bottom': {
            const left = rect.left + rect.width / 2;
            const top = rect.top + rect.height + spacing;
            const transform = 'translateX(-50%)';

            setPosition({ left, top, transform });
            break;
          }
          case 'top': {
            const left = rect.left + rect.width / 2;
            const top = rect.top - spacing;
            const transform = 'translateX(-50%) translateY(-100%)';

            setPosition({ left, top, transform });
            break;
          }
        }
      }
    };

    const mouseOut = () => {
      setPosition(undefined);
    };

    if (text) {
      current.addEventListener('mouseover', mouseOver);
      current.addEventListener('mouseout', mouseOut);
    }

    return () => {
      setPosition(undefined);

      current.removeEventListener('mouseover', mouseOver);
      current.removeEventListener('mouseout', mouseOut);
    };
  }, [position, text]);

  return (
    <div className={classNames('tooltip-target', className)} ref={ref}>
      {children}
      {createPortal(
        <div
          className={classNames(css.infoTooltip, 'bx--tooltip', { 'bx--tooltip-shown': cssPos !== undefined })}
          data-floating-menu-direction={position}
          role="tooltip"
          style={{
            marginTop: 0,
            ...cssPos,
          }}
        >
          <span className="bx--tooltip__caret" />
          <div className={css.content} tabIndex={-1} role="dialog">
            {text || null}
          </div>
        </div>,
        document.querySelector('#root')!,
      )}
    </div>
  );
};

export { Tooltip };
