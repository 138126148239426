import * as React from 'react';
import { useDebounce } from 'use-debounce';

import { useConstrainedTargetData } from '../../hooks/ConstrainedTargetData';
import { ConstraintRangesKeys, SelectedWell, useApplicationContext } from '../../context/ApplicationContext';
import { ConstraintScatterPlot } from '../molecules/ConstraintScatterPlot';
import { ScatterPlotAxes, ScatterPlotAxesSelect } from '../atoms/ScatterPlotAxesSelect';
import { useElementDimensions } from '../../hooks/Dimensions';

import css from './TargetScatterPlot.module.scss';

export interface ChartData {
  group: string;
  easting: number;
  northing: number;
  wellCost: number;
  drainLength: number;
  wellLength: number;
  minCCDistanceOverburden: number;
  minCCDistanceDrain: number;
  outstep: number;
  maxDlsShallowestSection: number;
  maxDlsDeepestSection: number;
  maxAzimuthalChange: number;
  kickOffDepth: number;
  name: string;
  well: SelectedWell;
}

interface TargetScatterPlotProps {
  tileTrigger?: boolean;
}

const TargetScatterPlot = React.memo<TargetScatterPlotProps>((props) => {
  const { tileTrigger } = props;
  const { constrainedSelectedTargetsData } = useConstrainedTargetData();
  const {
    setSelectedWell,
    selectedTargets,
    constraintRanges,
    getSelectedWell,
    drainDistanceValid,
  } = useApplicationContext();
  const [xAxis, setXAxis] = React.useState<ConstraintRangesKeys>('wellCost');
  const [yAxis, setYAxis] = React.useState<ConstraintRangesKeys>('maxDlsShallowestSection');
  const elementRef = React.useRef<HTMLDivElement>(null);
  const dimensions = useElementDimensions(elementRef, tileTrigger);

  const [debouncedSelectedTargets] = useDebounce(selectedTargets, 100);

  const handleWellSelect = (wellId: number | null) => {
    if (!wellId) {
      setSelectedWell(null);
      return;
    }

    const newWell: SelectedWell = {
      wellId,
      target: selectedTargets[0],
    };

    setSelectedWell(newWell);
  };

  const selectedWell = getSelectedWell();

  const data = React.useMemo(() => {
    const result = constrainedSelectedTargetsData.flatMap((target) =>
      target.filteredWells!.map((rawDataItem) => {
        return {
          group: target.title,
          easting: rawDataItem.easting,
          northing: rawDataItem.northing,
          wellCost: rawDataItem.wellCost,
          drainLength: rawDataItem.drainLength,
          wellLength: rawDataItem.wellLength,
          minCCDistanceOverburden: rawDataItem.minCCDistanceOverburden,
          minCCDistanceDrain: rawDataItem.minCCDistanceDrain,
          outstep: rawDataItem.outstep,
          maxDlsShallowestSection: rawDataItem.maxDlsShallowestSection,
          maxDlsDeepestSection: rawDataItem.maxDlsDeepestSection,
          maxAzimuthalChange: rawDataItem.maxAzimuthalChange,
          kickOffDepth: rawDataItem.kickOffDepth,
          hasDuplicateTarget: false,
          name: rawDataItem.name,
          well: {
            target: target.title,
            wellId: rawDataItem.id,
          },
        } as ChartData;
      }),
    );

    return result.sort((a, b) => {
      if (selectedWell && a.well.wellId === selectedWell.well.id) {
        return 1;
      }
      if (selectedWell && b.well.wellId === selectedWell.well.id) {
        return -1;
      }

      return 1;
    });
  }, [constrainedSelectedTargetsData, debouncedSelectedTargets, selectedWell, drainDistanceValid]);

  const width = dimensions.width < dimensions.height ? dimensions.width : dimensions.height;
  const height = dimensions.height < dimensions.width ? dimensions.height : dimensions.width;

  return (
    <div className={css.root} ref={elementRef}>
      {data.length > 0 && (
        <>
          <div>
            <ScatterPlotAxesSelect xAxis={xAxis} yAxis={yAxis} setXAxis={setXAxis} setYAxis={setYAxis} width={width} />
          </div>
          <div className={css.renderContainer}>
            <ConstraintScatterPlot
              onWellSelect={handleWellSelect}
              width={width - 45}
              height={height - 45}
              selectedWellId={selectedWell?.well.id}
              data={data}
              xAxis={xAxis}
              yAxis={yAxis}
              minX={constraintRanges[xAxis].min}
              maxX={constraintRanges[xAxis].max}
              minY={constraintRanges[yAxis].min}
              maxY={constraintRanges[yAxis].max}
              xName={ScatterPlotAxes.find((item) => item.id === xAxis)!.value}
              yName={ScatterPlotAxes.find((item) => item.id === yAxis)!.value}
              displayTooltip
            />
          </div>
        </>
      )}
    </div>
  );
});

TargetScatterPlot.displayName = 'TargetScatterPlot';

export { TargetScatterPlot };
