import { useState, useEffect, RefObject } from 'react';

interface WindowDimensions {
  width: number;
  height: number;
}

const getWindowDimensions = (): WindowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const getElementDimension = (ref: RefObject<HTMLElement>): WindowDimensions => {
  if (!ref.current) {
    return { width: 1, height: 1 };
  }

  return {
    width: ref.current.clientWidth,
    height: ref.current.clientHeight,
  };
};

const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};

const useElementDimensions = (ref: RefObject<HTMLElement>, trigger?: boolean | string): WindowDimensions => {
  const [dimensions, setDimensions] = useState<WindowDimensions>(getElementDimension(ref));

  useEffect(() => {
    function handleResize() {
      setDimensions(getElementDimension(ref));
    }

    setDimensions(getElementDimension(ref));

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [ref.current, trigger]);

  return dimensions;
};

export { useWindowDimensions, useElementDimensions };
