import * as React from 'react';
import { ChevronLeft16 } from '@carbon/icons-react';
import { RadioButtonGroup, RadioButton, Checkbox, Button } from 'carbon-components-react';
import classNames from 'classnames';
import { ZoomMapIcon } from './ZoomMapIcon';

import css from './TargetMapToolbox.module.scss';

interface TargetMapToolboxProps {
  toggleTargetWells: () => void;
  showTargetWells: boolean;
  toggleExistingWells: () => void;
  showExistingWells: boolean;
  toggleVisibility: (name: string) => void;
  isHardConstraintsVisible: boolean;
  toggleSoftConstraint: (name: string) => void;
  softConstraints: string[];
  softConstrainsVisible: string[];
  handleZoomedIn: (zoomedIn: boolean) => void;
  isZoomedIn: boolean;
}

const TargetMapToolbox = React.memo<TargetMapToolboxProps>((props) => {
  const {
    toggleTargetWells,
    showTargetWells,
    toggleExistingWells,
    showExistingWells,
    toggleVisibility,
    isHardConstraintsVisible,
    toggleSoftConstraint,
    softConstraints,
    softConstrainsVisible,
    handleZoomedIn,
    isZoomedIn,
  } = props;

  const [open, setOpen] = React.useState<boolean>(false);
  const [zoomLow, setZoomLow] = React.useState<boolean>(false);

  const handleToggle = () => {
    setOpen(!open);
    setZoomLow(!zoomLow);
  };

  const handleToggleVisibility = (name: string) => () => {
    toggleVisibility(name);
  };

  const handleToggleSoftConstraint = (name: string) => () => {
    toggleSoftConstraint(name);
  };

  return (
    <div className={classNames('toolbox', css.root, { [css.open]: open })}>
      <Button
        hasIconOnly
        renderIcon={ChevronLeft16}
        size="small"
        tooltipAlignment="start"
        tooltipPosition="bottom"
        className={css.toggle}
        iconDescription="Expand"
        onClick={handleToggle}
      />

      <div className={classNames(css.content)}>
        <div>Display</div>

        <div className={css.groupLabel}>New wells</div>
        <Checkbox
          id="target wells"
          labelText="Visible"
          onChange={toggleTargetWells}
          checked={showTargetWells}
        />

        <div className={css.groupLabel}>Existing wells</div>
        <Checkbox
          id="existing wells"
          labelText="Visible"
          onChange={toggleExistingWells}
          checked={showExistingWells}
        />

        <div className={css.groupLabel}>Soft constraints</div>
        {softConstraints.map((name, key) => (
          <Checkbox
            key={key}
            id={`soft-constraint-${name}`}
            labelText={name}
            onChange={handleToggleSoftConstraint(name)}
            checked={softConstrainsVisible.includes(name)}
          />
        ))}

        <div className={css.groupLabel}>Hard constraints</div>
        <Checkbox
          id="hard-constraints"
          labelText="All hard constraints"
          onChange={handleToggleVisibility('hard-constraints')}
          checked={isHardConstraintsVisible}
        />
      </div>

      <ZoomMapIcon zoomLow={zoomLow} zoomedIn={isZoomedIn} onClickHandle={handleZoomedIn} />
    </div>
  );
});

TargetMapToolbox.displayName = 'TargetMapToolbox';

export { TargetMapToolbox };
