import * as React from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow,
} from 'carbon-components-react';
import classNames from 'classnames';
import {
  CaretLeft16, CaretRight16, Edit16, TrashCan16,
} from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';

import { TargetMenu } from './TargetMenu';
import { PropertyMenu } from './PropertyMenu';
import { CampaignProperties, CampaignSorting } from '../templates/CampaignInfo';
import { RawData, useApplicationContext } from '../../context/ApplicationContext';
import { TargetWell } from '../../types/Targets';

import css from './CampaignInfoTable.module.scss';

const PAGE_SIZE = 10;

interface CampaignInfoTableProps {
  onRemoveFromCampaign: (campaignWell: TargetWell) => () => void;
  setSorting: (sorting: CampaignSorting) => void;
  property: CampaignProperties;
  setProperty: (property: CampaignProperties) => void;
  campaignWells: TargetWell[];
}

const CampaignInfoTable = React.memo<CampaignInfoTableProps>((props) => {
  const {
    onRemoveFromCampaign, setSorting, property, setProperty, campaignWells,
  } = props;
  const history = useHistory();
  const [page, setPage] = React.useState<number>(0);
  const {
    targetsData,
    selectedTargets,
    setSelectedTargets,
    setSelectedWell,
    getSelectedWell,
    setEditWell,
  } = useApplicationContext();

  const selectedWell = getSelectedWell();
  const selectedWellId = selectedWell?.well.id;

  const handleEdit = (campaignWell: RawData) => () => {
    const target = targetsData.find((targetData) => targetData.title === campaignWell.area);

    if (selectedTargets.indexOf(target!.title) < 0) {
      setSelectedTargets([target!.title]);
    }

    setSelectedWell({
      target: target!.title,
      wellId: campaignWell.id,
    });
    setEditWell({
      target: target!.title,
      wellId: campaignWell.id,
    });

    history.push('/target-view');
  };

  const handlePageRight = () => {
    if ((page + 1) * PAGE_SIZE < campaignWells.length) {
      setPage(page + 1);
    }
  };
  const handlePageLeft = () => {
    if (page - 1 >= 0) {
      setPage(page - 1);
    }
  };

  const handleEnter = (well: TargetWell) => () => {
    setSelectedWell({
      wellId: well.id,
      target: well.area,
    });
  };

  const handleLeave = () => () => {
    setSelectedWell(null);
  };


  return (
    <TableContainer className={`dark ${css.campaignInfoContainer}`}>
      <Table size="short">
        <TableHead>
          <TableRow>
            <TableHeader className={css.targetHeader}>
              Target
              <TargetMenu setSorting={setSorting} />
            </TableHeader>
            <TableHeader className={css.propertyHeader}>
              {property === CampaignProperties.MaxDlsShallowestSection && 'Max dls shallowest'}
              {property === CampaignProperties.MaxDlsDeepestSection && 'Max dls deepest'}
              {property === CampaignProperties.MaxSurface && 'Inc@seabed'}
              {property === CampaignProperties.AzimuthChange && 'Azi change'}
              {property === CampaignProperties.MaxDM && 'Well length'}
              <PropertyMenu setSorting={setSorting} property={property} setProperty={setProperty} />
            </TableHeader>
            <TableHeader className={css.headerClear}>Clear</TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {campaignWells.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE).map((campaignWell) => (
            <TableRow
              key={`${campaignWell.name}${campaignWell.id}`}
              onMouseEnter={handleEnter(campaignWell)}
              onMouseLeave={handleLeave()}
            >
              <TableCell className={classNames(css.wellName, { [css.highlight]: selectedWellId === campaignWell.id })}>
                {campaignWell.name}
              </TableCell>
              <TableCell
                className={classNames(css.propertyCell, {
                  [css.highlight]: selectedWellId === campaignWell.id,
                })}
              >
                {property === CampaignProperties.MaxDlsShallowestSection && campaignWell.maxDlsShallowestSection?.toFixed(0)}
                {property === CampaignProperties.MaxDlsDeepestSection && campaignWell.maxDlsDeepestSection?.toFixed(0)}
                {property === CampaignProperties.MaxSurface && campaignWell.maxSurfaceDip?.toFixed(0)}
                {property === CampaignProperties.AzimuthChange && campaignWell.azimuthChange?.toFixed(0)}
                {property === CampaignProperties.MaxDM && campaignWell.maxMD?.toFixed(0)}
              </TableCell>

              <TableCell className={classNames(css.iconCell, { [css.highlight]: selectedWellId === campaignWell.id })}>
                <TrashCan16 onClick={onRemoveFromCampaign(campaignWell)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              <div className={css.paginationCell}>
                <div className={css.pageIconContainer}>
                  <CaretLeft16
                    className={classNames(css.pageIcon, { [css.disabled]: page - 1 < 0 })}
                    onClick={handlePageLeft}
                  />
                </div>
                <div className={css.pageIconContainer}>
                  <CaretRight16
                    className={classNames(css.pageIcon, {
                      [css.disabled]: (page + 1) * PAGE_SIZE >= campaignWells.length,
                    })}
                    onClick={handlePageRight}
                  />
                </div>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

CampaignInfoTable.displayName = 'CampaignInfoTable';

export { CampaignInfoTable };
