import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput,
} from 'carbon-components-react';
import classNames from 'classnames';

import css from './CampaignNameDialog.module.scss';

interface CampaignNameDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAccept?: (name: string) => void;
  message?: string;
}

const CampaignNameDialog: React.FC<CampaignNameDialogProps> = (props): React.ReactElement => {
  const {
    open, setOpen, message, onAccept,
  } = props;

  const [name, setName] = React.useState<string>('');
  const [invalid, setInvalid] = React.useState<string>('');

  const handleAccept = () => {
    if (!name.trim()) {
      setInvalid('Please enter a name');

      return;
    }

    if (onAccept) {
      onAccept(name);
    }

    setOpen(false);
  };

  React.useEffect(() => {
    if (open) {
      setName('');
    }
  }, [open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className={classNames('dark-theme feedback-dialog', css.root)}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalHeader title="Please enter the name of this campaign" />
      <ModalBody>
        {message && <p>{message}</p>}
        <TextInput
          id={`campaign-name-${Math.random()}`}
          labelText="Campaign name"
          value={name}
          onChange={handleNameChange}
          invalidText={invalid}
          invalid={!!invalid}
        />
      </ModalBody>
      <ModalFooter
        secondaryButtonText="Cancel"
        onRequestClose={handleCancel}
        primaryButtonText="Accept"
        onRequestSubmit={handleAccept}
      />
    </ComposedModal>,
    document.body,
  );
};

export { CampaignNameDialog };
