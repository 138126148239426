import * as React from 'react';
import { AxiosResponse } from 'axios';

import {
  getFaultsGeometry, getHorizonsGeometries,
} from '@whitespace/app-3dviewer';

import { Coord } from '@whitespace/app-3dviewer/lib/types';

import axios from '../api/axios';
import {
  FieldObject, HorizonObject, useApplicationContext,
} from '../context/ApplicationContext';
import { GLOBAL_SCALE } from './TargetsLoader';

export interface Fault {
  id: number;
  fileName: string;
  title: string;
  visible: boolean;
}

export interface FaultFileItem {
  id: number;
  name: string;
  coords: Coord[];
  // eslint-disable-next-line camelcase
  fault_name?: string;
  // eslint-disable-next-line camelcase
  num_pts: number;
}

export interface HorizonsFileItem {
  id: number;
  name: string;
  coords: Coord[];
  // eslint-disable-next-line camelcase
  hor_name?: string;
}

const getData = <T>(fileName: string): Promise<AxiosResponse<T>> => {
  const root = window.location.href.substr(0, window.location.href.length - window.location.pathname.length + 1);

  return axios.get(`${root}${fileName}`);
};

export const useMeshData = (faults: Fault[], horizonsFileName: string) => {
  const {
    objectsOffset,
    setLoadedFieldObjects,
    loadedFieldObjects,
    increaseFilesLoaded,
    setHorizonObject,
    setHorizons,
  } = useApplicationContext();
  const [faultIndex, setFaultIndex] = React.useState<number>(0);

  const getHorizonData = async () => {
    const horizonResponse = await getData<HorizonsFileItem[]>(horizonsFileName);
    const horizonData = horizonResponse.data;

    const horizonObject: FieldObject = {
      title: 'Horizons',
      id: 1,
      geometry: getHorizonsGeometries(horizonData, GLOBAL_SCALE, objectsOffset!),
      rawdata: horizonData,
      interactive: false,
      visible: false,
      type: 'horizon',
    };

    const horizons: HorizonObject[] = horizonData.map((horizon, horizonId: number) => ({
      name: horizon.name,
      id: horizonId + 1,
      visible: false,
    }));

    setHorizons(horizons);
    increaseFilesLoaded();
    setHorizonObject(horizonObject);
  };

  const getFaultData = async (fault: Fault) => {
    const faultsResponse = await getData<FaultFileItem[]>(fault.fileName);
    const faultsData = faultsResponse.data;
    const newFieldObject: FieldObject = {
      title: fault.title,
      id: fault.id,
      geometry: [getFaultsGeometry(faultsData, GLOBAL_SCALE, objectsOffset!)],
      rawdata: faultsData,
      interactive: false,
      visible: false,
      type: 'faults',
    };

    increaseFilesLoaded();
    setLoadedFieldObjects([...loadedFieldObjects, newFieldObject]);
  };

  const getFaultsData = (): void => {
    getFaultData(faults[faultIndex]);

    setFaultIndex(faultIndex + 1);
  };

  React.useEffect(() => {
    if (loadedFieldObjects.length > 0 && faultIndex < faults.length) {
      getFaultsData();
    }
  }, [loadedFieldObjects.length]);

  return {
    getHorizonData,
    getFaultsData,
  };
};
