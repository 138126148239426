import * as React from 'react';

import { ConstraintRangesKeys, SelectedWell, useApplicationContext } from '../../context/ApplicationContext';
import { TargetWell } from '../../types/Targets';
import { ConstraintScatterPlot } from '../molecules/ConstraintScatterPlot';
import { ScatterPlotAxes, ScatterPlotAxesSelect } from '../atoms/ScatterPlotAxesSelect';
import { useElementDimensions } from '../../hooks/Dimensions';

import css from './CampaignScatterPlot.module.scss';
import { useSetRanges } from '../../hooks/SetRanges';

export interface CampaignScatterPlotProps {
  trigger: boolean;
}

const CampaignScatterPlot = React.memo<CampaignScatterPlotProps>((props) => {
  const { trigger } = props;
  const { campaignWells, getSelectedWell, setSelectedWell } = useApplicationContext();
  const elementRef = React.useRef<HTMLDivElement>(null);
  const dimensions = useElementDimensions(elementRef, trigger);
  const { getRanges } = useSetRanges();

  const [xAxis, setXAxis] = React.useState<ConstraintRangesKeys>('wellCost');
  const [yAxis, setYAxis] = React.useState<ConstraintRangesKeys>('maxDlsShallowestSection');

  const selectedWell = getSelectedWell();

  const handleWellSelect = (wellId: number | null) => {
    if (!wellId) {
      setSelectedWell(null);
      return;
    }

    const newWell: SelectedWell = {
      wellId,
      target: campaignWells.find((well) => well.id === wellId)!.area,
    };

    setSelectedWell(newWell);
  };

  const ranges = getRanges([{ wells: campaignWells }]);

  const data = React.useMemo(() => {
    const result = campaignWells.map((well: TargetWell, index) => ({
      group: 'Campaign wells',
      easting: well.easting,
      northing: well.northing,
      wellCost: well.wellCost,
      drainLength: well.drainLength,
      wellLength: well.wellLength,
      minCCDistanceOverburden: well.minCCDistanceOverburden,
      minCCDistanceDrain: well.minCCDistanceDrain,
      outstep: well.outstep,
      maxDlsShallowestSection: well.maxDlsShallowestSection,
      maxDlsDeepestSection: well.maxDlsDeepestSection,
      maxAzimuthalChange: well.maxAzimuthalChange,
      kickOffDepth: well.kickOffDepth,

      name: well.name,
      well: {
        target: well.target,
        wellId: well.id,
      },
    }));

    return result;
  }, [campaignWells, selectedWell]);

  const width = dimensions.width < dimensions.height ? dimensions.width : dimensions.height;
  const height = dimensions.height < dimensions.width ? dimensions.height : dimensions.width;

  return (
    <div className={css.root} ref={elementRef}>
      {data.length > 0 && (
        <>
          <div>
            <ScatterPlotAxesSelect xAxis={xAxis} yAxis={yAxis} setXAxis={setXAxis} setYAxis={setYAxis} />
          </div>
          <div className={css.renderContainer}>
            <ConstraintScatterPlot
              onHoverWellSelect={handleWellSelect}
              width={width - 45}
              height={height - 45}
              selectedWellId={selectedWell?.well.id}
              data={data}
              xAxis={xAxis}
              yAxis={yAxis}
              minX={ranges[xAxis].min}
              maxX={ranges[xAxis].max}
              minY={ranges[yAxis].min}
              maxY={ranges[yAxis].max}
              xName={ScatterPlotAxes.find((item) => item.id === xAxis)!.value}
              yName={ScatterPlotAxes.find((item) => item.id === yAxis)!.value}
              displayTooltip
            />
          </div>
        </>
      )}
    </div>
  );
});

CampaignScatterPlot.displayName = 'CampaignScatterPlot';

export { CampaignScatterPlot };
