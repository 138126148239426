import * as React from 'react';
import { Dropdown } from 'carbon-components-react';

import css from './ScatterPlotAxesSelect.module.scss';
import { ConstraintRangesKeys } from 'context/ApplicationContext';

interface ScatterPlotAxesSelectProps {
  xAxis: ConstraintRangesKeys;
  setXAxis: (name: ConstraintRangesKeys) => void;
  yAxis: ConstraintRangesKeys;
  setYAxis: (name: ConstraintRangesKeys) => void;
  addMargin?: boolean;
  width?: number;
}

export const ScatterPlotAxes = [
  { id: 'easting', value: 'Easting' },
  { id: 'northing', value: 'Northing' },
  { id: 'wellCost', value: 'Well cost' },
  { id: 'drainLength', value: 'Drain length' },
  { id: 'wellLength', value: 'Well length' },
  { id: 'minCCDistanceOverburden', value: 'Min c-c distance overburden' },
  { id: 'minCCDistanceDrain', value: 'Min c-c distance drain' },
  { id: 'outstep', value: 'Outstep' },
  { id: 'maxDlsShallowestSection', value: 'Max Dls shallowest section' },
  { id: 'maxDlsDeepestSection', value: 'Max Dls deepest section' },
  { id: 'maxAzimuthalChange', value: 'Max azimuthal change' },
  { id: 'kickOffDepth', value: 'Kick off depth' },
];

const ScatterPlotAxesSelect = React.memo<ScatterPlotAxesSelectProps>((props) => {
  const { xAxis, setXAxis, setYAxis, yAxis, addMargin = false, width = 1 } = props;

  const handleXSelect = (event: { selectedItem: { id: string; value: string } }) => {
    setXAxis(event.selectedItem.id as ConstraintRangesKeys);
  };

  const handleYSelect = (event: { selectedItem: { id: string; value: string } }) => {
    setYAxis(event.selectedItem.id as ConstraintRangesKeys);
  };

  const style = () => {
    const obj = {
      ...(addMargin && { marginRight: '1.75rem' }),
      ...(width > 420 && { gridTemplateColumns: '170px 30px 170px' }),
    };
    return obj;
  };

  return (
    <div className={css.root} style={style()}>
      <div>
        <Dropdown
          ariaLabel="Dropdown"
          id="target-selected"
          className="scatterplot"
          items={ScatterPlotAxes}
          label="Select target"
          titleText=""
          itemToString={(item) => item.value}
          onChange={handleXSelect}
          selectedItem={ScatterPlotAxes.find((item) => item.id === xAxis)}
        />
      </div>
      <div className={css.vs}>vs</div>
      <div>
        <Dropdown
          ariaLabel="Dropdown"
          id="target-selected"
          className="scatterplot"
          items={ScatterPlotAxes}
          label="Select target"
          titleText=""
          itemToString={(item) => item.value}
          selectedItem={ScatterPlotAxes.find((item) => item.id === yAxis)}
          onChange={handleYSelect}
        />
      </div>
    </div>
  );
});

ScatterPlotAxesSelect.displayName = 'ScatterPlotAxesSelect';

export { ScatterPlotAxesSelect };
