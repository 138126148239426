import JSZip from 'jszip';
import { round } from 'lodash';
import papaparse from 'papaparse';
import { saveAs } from 'file-saver';

import { TargetWell } from 'types/Targets';

export const exportWells = (wells: TargetWell[], filename: string) => {
  const header = wells.map((well) => ({
    name: well.name,
    xCoord: round(well.coords[0][0], 5),
    yCoord: round(well.coords[0][1], 5),
    KB: 225,
    measuredDepth: round(Math.max(...well.md), 5),
  }));
  const headerData = papaparse.unparse(header, {
    delimiter: '\t',
  });

  const trajectories = wells.map((well) => ({
    name: well.name,
    data: well.md.map((_, index) => [
      '',
      round(well.md[index], 5),
      round(well.inclination[index], 5),
      round(well.azimuth[index], 5),
    ]),
  }));
  const trajectoriesData = trajectories
    .map(
      ({ name, data }) =>
        `${name}\r\n${papaparse.unparse(data, {
          delimiter: '\t',
        })}`,
    )
    .join('\r\n\r\n');

  const zip = new JSZip();

  zip.file('header.txt', headerData);
  zip.file('trajectories.txt', trajectoriesData);

  zip.generateAsync({ type: 'blob' }).then((blob) => {
    saveAs(blob, `${filename}_export.zip`);
  });
};
