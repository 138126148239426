import * as React from 'react';

import css from './CampaignSummaryTile.module.scss';

interface CampaignSummaryTileProps {
  value: string;
  title: React.ReactNode;
}

const CampaignSummaryTile = React.memo<CampaignSummaryTileProps>((props) => {
  const { value, title } = props;
  return (
    <div className={css.root}>
      <div className={css.value}>{value}</div>
      <div className={css.title}>{title}</div>
    </div>
  );
});

CampaignSummaryTile.displayName = 'CampaignSummaryTile';

export { CampaignSummaryTile };
