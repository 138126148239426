import * as React from 'react';

import { AppHeader } from '../../components/templates/AppHeader';
import { FlowNavigation } from '../../components/organisms/FlowNavigation';
import { Targets } from '../../components/templates/Targets';
import { TargetContent } from '../../components/templates/TargetContent';
import { PageContent } from '../../components/templates/PageContent';
import { Credits } from '../../components/templates/Credits';

import css from './TargetView.module.scss';

const TargetView = React.memo(() => (
  <div>
    <AppHeader />
    <FlowNavigation />
    <PageContent className={css.content}>
      <Targets />
      <TargetContent />
    </PageContent>
      <Credits />
  </div>
));

TargetView.displayName = 'TargetView';

export { TargetView };
