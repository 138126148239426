import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import '@whitespace/internal-whitespace-frontend-package/dist/css/index.css';
import '@whitespace/app-3dviewer/app-3dviewer.esm.css';

import { ApplicationProvider } from './context/ApplicationContext';
import { LoginContainer } from './features/login/LoginContainer';

import './index.scss';

ReactDOM.render(
  <Router>
    <ApplicationProvider>
      <LoginContainer />
    </ApplicationProvider>
  </Router>,
  document.getElementById('root'),
);
