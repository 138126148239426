import * as React from 'react';
import { HeaderMenuItem, HeaderMenu } from 'carbon-components-react';

import { CampaignPayload } from '../../hooks/campaign';

interface CampaignListProps {
  campaigns: CampaignPayload[];
  onCampaignSelect: (campaign: CampaignPayload) => void;
  onCreateCampaign: () => void;
}

const CampaignList = React.memo<CampaignListProps>((props) => {
  const { campaigns, onCampaignSelect, onCreateCampaign } = props;

  const handleCampaignSelect = (campaign: CampaignPayload) => () => {
    onCampaignSelect(campaign);
  };

  const sorted = campaigns.sort((a, b) => b.created!.localeCompare(a.created!));

  return (
    <HeaderMenu aria-label="Select campaign" menuLinkName="Campaign">
      <HeaderMenuItem onClick={onCreateCampaign}>Create new</HeaderMenuItem>
      {sorted.map((campaign) => (
        <HeaderMenuItem key={campaign.id!} onClick={handleCampaignSelect(campaign)}>
          {new Date(campaign.created!).toLocaleDateString()} {campaign.name}
        </HeaderMenuItem>
      ))}
    </HeaderMenu>
  );
});

CampaignList.displayName = 'CampaignList';

export { CampaignList };
