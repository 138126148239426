import * as React from 'react';
import { OverflowMenu, OverflowMenuItem, RadioButton } from 'carbon-components-react';
import classNames from 'classnames';

import { CaretDown20, CaretSort20, CaretUp20 } from '@carbon/icons-react';

import { CampaignProperties, CampaignSorting } from '../templates/CampaignInfo';

import css from './PropertyMenu.module.scss';

interface PropertyMenuProps {
  setSorting: (sorting: CampaignSorting) => void;
  setProperty: (property: CampaignProperties) => void;
  property: CampaignProperties;
}

const PropertyMenu = React.memo<PropertyMenuProps>((props) => {
  const { setSorting, property, setProperty } = props;

  const handleSortUp = () => {
    setSorting(CampaignSorting.PropertyDesc);
  };

  const handleSortDown = () => {
    setSorting(CampaignSorting.PropertyAsc);
  };

  const handleSetProperty = (newProperty: CampaignProperties) => () => {
    setProperty(newProperty);
  };

  const getPropertyClassName = (): string => {
    if ([
      CampaignProperties.MaxDlsShallowestSection, 
      CampaignProperties.MaxDlsDeepestSection
    ].includes(property)) {
      return '.maxDls';
    }

    return '.azimuthChange';
  };

  return (
    <OverflowMenu
      ariaLabel="Target"
      className={css.root}
      renderIcon={CaretSort20}
      flipped
      selectorPrimaryFocus={getPropertyClassName()}
    >
      <OverflowMenuItem
        onClick={handleSortUp}
        itemText={
          <>
            <CaretUp20 className={css.optionIcon} /> Sort up
          </>
        }
      />
      <OverflowMenuItem
        onClick={handleSortDown}
        itemText={
          <>
            <CaretDown20 className={css.optionIcon} /> Sort down
          </>
        }
      />
      <OverflowMenuItem
        hasDivider
        className={classNames('maxDls', css.property)}
        itemText={
          <>
            <RadioButton
              checked={property === CampaignProperties.MaxDlsShallowestSection}
              className={css.radio}
              name="property-select"
              value={CampaignProperties.MaxDlsShallowestSection}
            />
            Max dls shallowest section
          </>
        }
        onClick={handleSetProperty(CampaignProperties.MaxDlsShallowestSection)}
      />
      <OverflowMenuItem
        hasDivider
        className={classNames('maxDls', css.property)}
        itemText={
          <>
            <RadioButton
              checked={property === CampaignProperties.MaxDlsDeepestSection}
              className={css.radio}
              name="property-select"
              value={CampaignProperties.MaxDlsDeepestSection}
            />
            Max dls deepest section
          </>
        }
        onClick={handleSetProperty(CampaignProperties.MaxDlsDeepestSection)}
      />
      <OverflowMenuItem
        className={classNames('azimuthChange', css.property)}
        itemText={
          <>
            <RadioButton
              checked={property === CampaignProperties.AzimuthChange}
              className={css.radio}
              name="property-select"
              value={CampaignProperties.AzimuthChange}
            />
            Azi change
          </>
        }
        onClick={handleSetProperty(CampaignProperties.AzimuthChange)}
      />
      <OverflowMenuItem
        className={classNames('maxLength', css.property)}
        itemText={
          <>
            <RadioButton
              checked={property === CampaignProperties.MaxDM}
              className={css.radio}
              name="property-select"
              value={CampaignProperties.MaxDM}
            />
            Well length
          </>
        }
        onClick={handleSetProperty(CampaignProperties.MaxDM)}
      />
    </OverflowMenu>
  );
});

PropertyMenu.displayName = 'PropertyMenu';

export { PropertyMenu };
