import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  Button, ComposedModal, ModalBody, ModalFooter, ModalHeader,
} from 'carbon-components-react';

interface AcknowledgementModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onAccept?: () => void;
  message: string;
}

const AcknowledgementModal: React.FC<AcknowledgementModalProps> = (props): React.ReactElement => {
  const {
    open, setOpen, onAccept, message,
  } = props;

  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }

    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return ReactDOM.createPortal(
    <ComposedModal
      className="dark-theme feedback-dialog"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalHeader title="Are you sure?" />
      <ModalBody>{message}</ModalBody>
      <ModalFooter
        secondaryButtonText="Cancel"
        onRequestClose={handleCancel}
        primaryButtonText="Accept"
        onRequestSubmit={handleAccept}
      />
    </ComposedModal>,
    document.body,
  );
};

export { AcknowledgementModal };
