import * as React from 'react';
import {
  HeaderGlobalAction,
  HeaderGlobalBar,
  HeaderName,
  SkipToContent,
  HeaderNavigation,
  HeaderMenu,
  HeaderMenuItem,
} from 'carbon-components-react';
import { Link, LinkProps, useHistory } from 'react-router-dom';

import Logout from '@carbon/icons-react/lib/logout/20';
import Collaborate from '@carbon/icons-react/lib/collaborate/20';

import { Header } from '../organisms/Header';
import { Field, useApplicationContext } from '../../context/ApplicationContext';
import { FeedbackDialogButton } from '../organisms/FeedbackDialogButton';
import { CampaignNameDialog } from '../organisms/CampaignNameDialog';
import { TargetWell } from '../../types/Targets';
import { CampaignPayload, useCreateCampaign, useFetchCampaigns } from '../../hooks/campaign';
import { useNegativeTriggerEffect } from '../../hooks/NegativeTriggeredEffect';
import { CampaignList } from '../organisms/CampaignList';

import css from './AppHeader.module.scss';

const FIELD_NAMES = {
  [Field.NIMR_A]: "Nimr A",
  [Field.NIMR_E]: "Nimr E",
  [Field.NIMR_E_INJ]: "Nimr E (inj)",
}

const AppHeader: React.FC = React.memo(() => {
  const {
    field,
    setField,
    onLogout,
    setShowCredits,
    setNewNotification,
    campaigns,
    targetsData,
    setCampaignWells,
    setSelectedCampaign,
    selectedCampaign,
  } = useApplicationContext();
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const { isCreating, createError, createCampaign } = useCreateCampaign();
  const { refreshList, fetchError, isFetching } = useFetchCampaigns();
  const history = useHistory();

  React.useEffect(() => {
    refreshList();
    // NOTE: this triggers too often because AppHeader keeps getting recreated
    // It is needed because without it the campaign list does not update when changing field
  }, [field])

  useNegativeTriggerEffect(() => {
    if (fetchError) {
      setNewNotification('Error fetching stored campaigns', 'error');
    }
  }, isFetching);

  const handleCreateNewCampaign = () => {
    setOpen(true);
  };

  const handleShowCredits = () => {
    setShowCredits(true);
  };

  const handleCreateCampaign = (name: string) => {
    setCampaignWells([]);

    createCampaign(name, []);
  };

  const handleCampaignSelect = (campaign: CampaignPayload) => {
    const filteredWells = campaign.well_list.filter((wellListItem) => {
      const target = targetsData.find((findTarget) => findTarget.title === wellListItem.target_name);

      if (!target) {
        return false;
      }

      return target.wells.find((well) => well.wellId === wellListItem.well_id);
    });

    const wells = filteredWells.map<TargetWell>((wellListItem) => {
      const target = targetsData.find((findTarget) => findTarget.title === wellListItem.target_name);

      return target!.wells.find((well) => well.wellId === wellListItem.well_id)!;
    });

    setSelectedCampaign(campaign);
    setCampaignWells(wells);
    history.push('/campaign-view');
  };

  const handleViewCampaign = () => {
    if (selectedCampaign) {
      history.push('/campaign-view');
    }
  };

  useNegativeTriggerEffect(() => {
    if (createError) {
      setNewNotification('Error creating campaign', 'error');
    } else {
      setNewNotification('New campaign has been created');
      refreshList();
    }
  }, isCreating);

  return (
    <>
      <Header>
        <SkipToContent />
        <HeaderName<LinkProps> className={css.headerName} element={Link} to="/" aria-label="project title" prefix="">
          PDO Nimr&nbsp;<span className={css.headerNameTitle}>Well trajectory planning</span>
        </HeaderName>

        <HeaderNavigation aria-label="PDO Nimr A well trajectory planning">
          <HeaderMenu aria-label="Select field" menuLinkName={`Field (${FIELD_NAMES[field]})`}>
            {Object.entries(FIELD_NAMES).map(([fieldId, fieldName], index) => (
              <HeaderMenuItem key={index} onClick={() => setField(fieldId as Field)}>{fieldName}</HeaderMenuItem>
            ))}
          </HeaderMenu>
          <CampaignList
            campaigns={campaigns}
            onCampaignSelect={handleCampaignSelect}
            onCreateCampaign={handleCreateNewCampaign}
          />
          {selectedCampaign && (
            <div className={css.campaignBadge}>
              <span className={css.campaignBadgeContent}>{selectedCampaign.name}</span>
            </div>
          )}
        </HeaderNavigation>

        <HeaderGlobalBar>
          <HeaderGlobalAction aria-label="Search" onClick={handleShowCredits}>
            <Collaborate />
          </HeaderGlobalAction>

          <FeedbackDialogButton />

          <HeaderGlobalAction aria-label="Search" onClick={onLogout}>
            <Logout />
          </HeaderGlobalAction>
        </HeaderGlobalBar>
      </Header>
      <CampaignNameDialog open={isOpen} setOpen={setOpen} onAccept={handleCreateCampaign} />
    </>
  );
});

AppHeader.displayName = 'AppHeader';

export { AppHeader };
