import * as React from 'react';
import {
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  Borders,
  DiscreteColorLegend,
} from 'react-vis';
import { curveCatmullRom } from 'd3-shape';

interface SideViewRenderProps {
  data: { x: number; y: number }[];
  kolmuleData: { x: number; y: number }[];
  topStoeData: { x: number; y: number }[];
  bottomStoeData: { x: number; y: number }[];
  seaFloorData: { x: number; y: number }[];
  tangentData: { x: number; y: number }[];
  fuglenData: { x: number; y: number }[];
  faultData: Array<{ x: number; y: number }[]>;
  width: number;
  height: number;
  domains: { xDomain: number[]; yDomain: number[] };
}

const SideViewRender = React.memo<SideViewRenderProps>((props) => {
  const {
    data,
    width,
    height,
    kolmuleData,
    topStoeData,
    bottomStoeData,
    seaFloorData,
    faultData,
    tangentData,
    fuglenData,
    domains,
  } = props;

  const series = [
    {
      title: 'Well',
      color: '#12939A',
    },
    {
      title: 'Kolmule',
      color: '#79C7E3',
    },
    {
      title: 'Top stø',
      color: '#1A3177',
    },
    {
      title: 'Base stø',
      color: '#FF9833',
    },
    {
      title: 'Seafloor',
      color: '#EF5D28',
    },
    {
      title: 'Fuglen',
      color: '#12939A',
    },
    {
      title: 'Tangent',
      color: 'black',
    },
    {
      title: 'Faults',
      color: '#aa3b00',
    },
  ];

  const parseValue = (value: any) => {
    const parsed = parseFloat(value);
    const fixed = 40 * Math.round(value / 40);

    if (parsed !== fixed) {
      return '';
    }

    return value;
  };

  return (
    <div>
      <XYPlot height={height} width={width} xDomain={domains.xDomain} yDomain={domains.yDomain}>
        {faultData.map((fault, index) => (
          <LineSeries style={{ stroke: '#aa3b00' }} key={index} data={fault} curve={curveCatmullRom.alpha(0.5)} />
        ))}
        <Borders style={{ all: { fill: '#fff' } }} />

        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis title="lateral position (m)" tickFormat={parseValue} />
        <YAxis title="mTVDss" />
        <DiscreteColorLegend items={series} orientation="horizontal" />

        <LineSeries style={{ stroke: '#79C7E3' }} data={kolmuleData} curve={curveCatmullRom.alpha(0.5)} />
        <LineSeries style={{ stroke: '#1A3177' }} data={topStoeData} curve={curveCatmullRom.alpha(0.5)} />
        <LineSeries style={{ stroke: '#FF9833' }} data={bottomStoeData} curve={curveCatmullRom.alpha(0.5)} />
        <LineSeries style={{ stroke: '#EF5D28' }} data={seaFloorData} curve={curveCatmullRom.alpha(0.5)} />
        <LineSeries style={{ stroke: '#12939A' }} data={fuglenData} curve={curveCatmullRom.alpha(0.5)} />

        <LineSeries style={{ stroke: '#12939A' }} data={data} curve={curveCatmullRom.alpha(0.5)} />

        <LineSeries style={{ stroke: 'black', strokeWidth: 6 }} data={tangentData} curve={curveCatmullRom.alpha(0.5)} />
      </XYPlot>
    </div>
  );
});

SideViewRender.displayName = 'SideView';

export { SideViewRender };
