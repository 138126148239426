import { AxiosResponse } from 'axios';

import { getWellsGeometry, getPointsGeometry } from '@whitespace/app-3dviewer';

import { Field, RawData, useApplicationContext } from '../context/ApplicationContext';
import { getData } from '../utils/getData';
import { Target, TargetWell } from '../types/Targets';
import { getRanges } from '../utils/getRanges';

export const GLOBAL_SCALE = 1 / 30;

export interface TargetImport {
  name: string;
  easting: string;
  northing: string;
  tvd: string;
  latitude: string;
  longitude: string;
}

export interface TargetDataImport {
  id: number;
  target: string;
  area: string;
  flipped: boolean;
  well: string;
  name: string;
  easting: number;
  northing: number;
  reroute_roads: boolean;
  reroute_flow_lines: boolean;
  reroute_cables: boolean;
  cost: number;
  drain_length: number;
  wellLength: number;
  min_cc_dist_overburden: number;
  min_cc_drain_section: number;
  outstep: number;
  max_dls_shallowest_section: number;
  max_dls_deepest_section: number;
  azimuth_change: number;
  ko_depth: number;
  is_in_polymer_poly: boolean;

  // eslint-disable-next-line camelcase
  max_dog_leg: number;
  // eslint-disable-next-line camelcase
  dog_leg: number[];
  // eslint-disable-next-line camelcase
  inclination: number[];
  // eslint-disable-next-line camelcase
  azimuth: number[];
  // eslint-disable-next-line camelcase
  surface_x: number;
  // eslint-disable-next-line camelcase
  surface_y: number;
  // eslint-disable-next-line camelcase
  max_surface_dip: number;
  // eslint-disable-next-line camelcase
  mean_surface_dip: number;
  // eslint-disable-next-line camelcase
  // azimuth_change: number;
  // eslint-disable-next-line camelcase
  distance_from_original_target: number;
  // eslint-disable-next-line camelcase
  well_pcoords: number[][];
  // eslint-disable-next-line camelcase
  kolmule_pcoords: number[][];
  // eslint-disable-next-line camelcase
  top_stoe_pcoords: number[][];
  // eslint-disable-next-line camelcase
  bottom_stoe_pcoords: number[][];
  // eslint-disable-next-line camelcase
  seafloor_pcoords: number[][];
  // eslint-disable-next-line camelcase
  tangent_pcoords: number[][];
  // eslint-disable-next-line camelcase
  top_fuglen_pcoords: number[][];
  // eslint-disable-next-line camelcase
  fault_pcoords?: number[][][];
  // eslint-disable-next-line camelcase
  inj_drain_far_from_faults: boolean;
  // eslint-disable-next-line camelcase
  max_attack_angle_to_faults: number;
  // eslint-disable-next-line camelcase
  anomaly_distance: number;
  // eslint-disable-next-line camelcase
  pcoords_range: number[];
  md: number[];
  coords: number[][];
}

interface TargetFilenames {
  [key: string]: string[]
}

export const useTargetsLoader = (initialTargets?: TargetFilenames) => {
  const { setTargets, setObjectsOffset, setTargetsData, increaseFilesLoaded, setTargetsLoaded } = useApplicationContext();

  const deriveWellName = (well: TargetDataImport, index: number) => {
    return `${well.area}_well${index}`
  }

  const convertData = (targetName: string, data: TargetDataImport[], offset: number[], index: number): Target => {
    const firstCoords = data.map<number[]>((targetImportData) => targetImportData.coords[0]);
    const ranges = getRanges([firstCoords]);

    return {
      title: targetName,
      id: index,
      wells: data.map<TargetWell>((targetImportData, index) => ({
        coords: targetImportData.coords,
        md: targetImportData.md,
        dogLeg: targetImportData.dog_leg,
        inclination: targetImportData.inclination,
        azimuth: targetImportData.azimuth,
        id: Math.round(Math.random() * 99999999),
        wellId: index,
        name: deriveWellName(targetImportData, index),
        target: targetImportData.target,
        area: targetImportData.area,
        flipped: targetImportData.flipped,
        well: targetImportData.well,

        easting: targetImportData.easting,
        northing: targetImportData.northing,
        rerouteRoads: targetImportData.reroute_roads,
        rerouteFlowLines: targetImportData.reroute_flow_lines,
        rerouteCables: targetImportData.reroute_cables,
        wellCost: targetImportData.cost,
        drainLength: targetImportData.drain_length,
        wellLength: Math.max(...targetImportData.md),
        minCCDistanceOverburden: targetImportData.min_cc_dist_overburden,
        minCCDistanceDrain: targetImportData.min_cc_drain_section,
        outstep: targetImportData.outstep,
        maxDlsShallowestSection: targetImportData.max_dls_shallowest_section,
        maxDlsDeepestSection: targetImportData.max_dls_deepest_section,
        maxAzimuthalChange: targetImportData.azimuth_change,
        kickOffDepth: targetImportData.ko_depth,
        rerouteAccessRoads: targetImportData.reroute_roads,
        rerouteCathodicFeatures: targetImportData.reroute_flow_lines,
        rerouteBuriedPowerlines: targetImportData.reroute_cables,
        isInPolymerPoly: targetImportData.is_in_polymer_poly,

        surfaceX: targetImportData.surface_x,
        surfaceY: targetImportData.surface_y,
        maxSurfaceDip: targetImportData.max_surface_dip,
        meanSurfaceDip: targetImportData.mean_surface_dip,
        // maxDls: targetImportData.max_dog_leg,
        azimuthChange: Math.abs(targetImportData.azimuth_change),
        projectedCoordinates: targetImportData.well_pcoords,
        kolmuleProjectedCoordinates: targetImportData.kolmule_pcoords,
        topStoeProjectedCoordinates: targetImportData.top_stoe_pcoords,
        bottomStoeProjectedCoordinates: targetImportData.bottom_stoe_pcoords,
        seaFloorProjectedCoordinates: targetImportData.seafloor_pcoords,
        maxAttackAngleToFaults: targetImportData.max_attack_angle_to_faults,
        tangentProjectionCoordinates: targetImportData.tangent_pcoords ? targetImportData.tangent_pcoords : [],
        faultProjectedCoordinates: targetImportData.fault_pcoords ? targetImportData.fault_pcoords : [],
        fuglenProjectionCoordinates: targetImportData.top_fuglen_pcoords,
        anomalyDistance: targetImportData.anomaly_distance,
        projectionRanges: {
          x: {
            min: -1000000, // targetImportData.pcoords_range[0],
            max: 1000000, // targetImportData.pcoords_range[2],
          },
          y: {
            min: -1000000, // targetImportData.pcoords_range[1],
            max: 1000000, // targetImportData.pcoords_range[3],
          },
        },
        maxMD: Math.max(...targetImportData.md),
        distanceOriginalTarget: targetImportData.distance_from_original_target,
        drainDistanceValid: targetImportData.inj_drain_far_from_faults,
      })),
      fileName: '',
      geometry: getWellsGeometry(data as any, GLOBAL_SCALE, offset),
      ranges,
      wellCount: data.filter((targetImportData) => !targetImportData.flipped).length,
      flippedWellCount: data.filter((targetImportData) => targetImportData.flipped).length,
    };
  };

  const loadTargetData = async (field: Field, targets: string[]) => {

    let offset: number[] | null = null

    const targetsData = await Promise.all(targets.map(async (targetName, index) => {
      const { data } = await getData<TargetDataImport[]>(`meshes/${field}/targets/${targetName}.json`);

      increaseFilesLoaded();

      if(offset === null) {
        if (!data.length) {
          throw new Error('The first target requires coordinates');
        }
        offset = data[0].coords[0]
      }

      const convertedData = convertData(targetName, data, offset, index);
      return convertedData
    }))

    setObjectsOffset(offset);

    setTargetsLoaded(targetsLoaded => [...targetsLoaded, ...targetsData.map(target => target.title)])
    setTargetsData(existingData => [...existingData, ...targetsData]);
  };

  const loadTargets = async (field: Field, targets?: string[]) => {
    await loadTargetData(field, targets ?? initialTargets![field].slice(0, 1));
  };

  return {
    loadTargets,
    convertData,
  };
};
