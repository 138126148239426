import React, { useMemo, useState } from 'react';
import {
  RadioButtonGroup, RadioButton, Checkbox, Button,
} from 'carbon-components-react';
import classNames from 'classnames';

import { ChevronLeft16 } from '@carbon/icons-react';

import { FieldObject, HorizonObject } from '../../../context/ApplicationContext';

import css from './index.module.scss';

interface Props {
  parentDimensions: { width: number; height: number };
  toggleHorizonVisibility: (horizon: HorizonObject) => void;
  horizons: HorizonObject[];
  scale: number;
  fieldObjects: FieldObject[];
  showExistingWellsTooltips: boolean;
  onFieldObjectVisibilityToggle: (fieldObject: FieldObject, index: number) => void;
  onScaleChange: (scale: number) => void;
  onRecenter: () => void;
  targetVisibility: string;
  setTargetVisibility: (name: string) => void;
  transparentHorizons: boolean;
  transparentFaults: boolean;
  manualWellsVisible: boolean;
  toggleManualWellsVisible: () => void;
  existingWellsHighlighted: boolean;
  toggleExistingWellsHighlighted: () => void;
  toggleTransparency: (transparencyType: string) => void;
}

const LABEL_NAMES = {
  existing_wells: 'Existing wells',
  horizon: 'Horizons',
  faults: 'Faults',
  constraint: 'Obstacles',
};

const Toolbox = (props: Props) => {
  const {
    horizons,
    toggleHorizonVisibility,
    scale,
    fieldObjects,
    parentDimensions,
    showExistingWellsTooltips,
    onScaleChange,
    onFieldObjectVisibilityToggle,
    onRecenter,
    targetVisibility,
    setTargetVisibility,
    transparentFaults,
    transparentHorizons,
    toggleTransparency,
    manualWellsVisible,
    toggleManualWellsVisible,
    existingWellsHighlighted,
    toggleExistingWellsHighlighted,
  } = props;
  const [open, setOpen] = useState(false);
  const groupedData = useMemo(
    () =>
      fieldObjects
        .filter((fieldObject) => fieldObject.type !== 'horizon')
        .reduce((acc, value) => {
          if (!acc[value.type]) {
            acc[value.type] = [];
          }
          acc[value.type].push(value);
          return acc;
        }, {}),
    [fieldObjects],
  );

  const handleToggleHorizonVisibility = (horizon: HorizonObject) => () => {
    toggleHorizonVisibility(horizon);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handeSetTargetVisibility = (value: string) => {
    setTargetVisibility(value);
  };

  const handleTransparency = (transparencyType: string) => () => {
    toggleTransparency(transparencyType);
  };

  const style = {
    bottom: parentDimensions.height <= 780 ? '0' : undefined,
    height: parentDimensions.height > 780 ? '750px' : undefined,
  };

  return (
    <div className={classNames('toolbox', css.root, { [css.open]: open })} style={style}>
      <Button
        hasIconOnly
        renderIcon={ChevronLeft16}
        size="small"
        tooltipAlignment="start"
        tooltipPosition="bottom"
        className={css.toggle}
        iconDescription="Expand"
        onClick={handleToggle}
      />
      <div className={css.content}>
        <div>Display</div>
        <div className={css.inner}>
          <div>
            <div className={css.groupLabel}>Horizons</div>
            {horizons.map((horizon) => (
              <Checkbox
                key={horizon.id}
                id={`horizon-${horizon.id}`}
                labelText={horizon.name}
                checked={horizon.visible}
                onChange={handleToggleHorizonVisibility(horizon)}
              />
            ))}
          </div>

          {Object.keys(groupedData).map((key) => (
            <div key={key}>
              <div className={css.groupLabel}>{LABEL_NAMES[key]}</div>
              {groupedData[key].map((fieldObject: FieldObject) => (
                <Checkbox
                  key={fieldObject.id}
                  id={`field-object-${fieldObject.id}`}
                  labelText={fieldObject.title}
                  onChange={() => {
                    onFieldObjectVisibilityToggle(fieldObject, fieldObjects.indexOf(fieldObject));
                  }}
                  checked={fieldObject.visible}
                />
              ))}
            </div>
          ))}
          <div className={css.groupLabel}>Enable transparency</div>

          <Checkbox
            id="transparency-horizons"
            labelText="Horizons"
            onChange={handleTransparency('horizons')}
            checked={transparentHorizons}
          />

          <Checkbox
            id="transparency-faults"
            labelText="Faults"
            onChange={handleTransparency('faults')}
            checked={transparentFaults}
          />

          <div className={css.groupLabel}>Existing wells</div>

          <Checkbox
            id="wells-existing-visible"
            labelText="Visible"
            onChange={toggleManualWellsVisible}
            checked={manualWellsVisible}
          />
          <Checkbox
            id="wells-existing-highlighted"
            labelText="Highlighted"
            onChange={toggleExistingWellsHighlighted}
            checked={existingWellsHighlighted}
          />

          <div className={css.groupLabel}>Z-Scaling</div>

          <RadioButtonGroup
            orientation="vertical"
            name="radio-button-group"
            valueSelected={scale}
            onChange={onScaleChange}
            className="mini-table__group"
          >
            <RadioButton labelText="100%" value={1} className="mini-table__column" />
            <RadioButton labelText="300%" value={3} className="mini-table__column" />
          </RadioButtonGroup>

          <div className={css.groupLabel}>Targets</div>

          <RadioButtonGroup
            orientation="vertical"
            name="visibility-radio"
            valueSelected={targetVisibility}
            onChange={handeSetTargetVisibility}
            className="mini-table__group"
          >
            <RadioButton labelText="None" value="none" className="mini-table__column" />
            <RadioButton labelText="Original" value="original" className="mini-table__column" />
            <RadioButton labelText="Updated" value="actual" className="mini-table__column" />
          </RadioButtonGroup>

          <div className={css.groupLabel}>Reset</div>
          <Button className={css.resetButton} onClick={onRecenter}>
            Reset view
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Toolbox;
