import * as React from 'react';

import { AppHeader } from '../../components/templates/AppHeader';
import { FlowNavigation } from '../../components/organisms/FlowNavigation';
import { PageContent } from '../../components/templates/PageContent';
import { Credits } from '../../components/templates/Credits';
import { AllWells } from '../../components/templates/AllWells';

import css from './AllWellsView.module.scss';

const AllWellsView = React.memo(() => (
  <div>
    <AppHeader />
    <FlowNavigation />
    <PageContent className={css.content}>
      <AllWells />
    </PageContent>
    <Credits />
  </div>
));

AllWellsView.displayName = 'AllWellsView';

export { AllWellsView };
