import * as React  from 'react';
import classNames from 'classnames';

import { TooltipIcon } from 'carbon-components-react';
import { ZoomIn16, ZoomOut16 } from '@carbon/icons-react';

import css from './ZoomMapIcon.module.scss';

interface ZoomMapIconProps {
  onClickHandle?: (zoomedIn: boolean) => void;
  iconColor?: string;
  zoomedIn: boolean;
  zoomLow: boolean;
}

const ZoomMapIcon = React.memo<ZoomMapIconProps>(({ onClickHandle, iconColor, zoomedIn, zoomLow }) => {
  const color: { color?: string } = {};
  const toggleState = () => {
    if (onClickHandle) {
      if (zoomedIn) {
        onClickHandle(false);
      } else {
        onClickHandle(true);
      }
    }
  };

  if (iconColor) {
    color.color = iconColor;
  }

  return (
    <div className={classNames(css.root, { [css.low]: zoomLow })} onClick={toggleState} style={color}>
      {zoomedIn ? (
        <TooltipIcon tooltipText="Zoom out" direction="left">
          <ZoomOut16 style={color} />
        </TooltipIcon>
      ) : (
        <TooltipIcon tooltipText="Zoom in" direction="left">
          <ZoomIn16 style={color} />
        </TooltipIcon>
      )}
    </div>
  );
});

ZoomMapIcon.displayName = 'ZoomMapIcon';

export { ZoomMapIcon };
