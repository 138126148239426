export const filesLoadedReducer = (filesLoaded: number, action: string) => {
  if (action === 'increase') {
    return filesLoaded + 1;
  }
  if(action === 'reset') {
    return 0;
  }

  return filesLoaded;
};
