import React, { useState } from 'react';
import { HeaderGlobalAction } from 'carbon-components-react';

import { Debug20 } from '@carbon/icons-react';

import { FeedbackDialog } from './FeedbackDialog';

const FeedbackDialogButton = React.memo(() => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <HeaderGlobalAction aria-label="Send feedback" title="Send feedback" onClick={() => setOpen(true)}>
        <Debug20 />
      </HeaderGlobalAction>
      <FeedbackDialog open={open} setOpen={setOpen} />
    </>
  );
});

FeedbackDialogButton.displayName = 'FeedbackDialogButton';

export { FeedbackDialogButton };
