import * as React from 'react';
import css from './CardLabel.module.scss';
import classNames from 'classnames';

interface CardLabelProps {
  text: React.ReactNode | string;
  radioTitle?: React.ReactNode | boolean;
  campaignTitle?: React.ReactNode | boolean;
}

const CardLabel = React.memo<CardLabelProps>((props) => {
  const { text, radioTitle, campaignTitle } = props;

  return (
    <h5 className={classNames(css.root, { [css.radioTitle]: radioTitle }, { [css.campaignTitle]: campaignTitle })}>
      {text}
    </h5>
  );
});

CardLabel.displayName = 'CardLabel';

export { CardLabel };
