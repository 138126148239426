import * as React from 'react';
import {
  createCampaign as apiCreateCampaign,
  fetchAllCampaigns,
  removeCampaign as apiRemoveCampaign,
  updateCampaign as apiUpdateCampaign,
} from '../api/campaign';
import { useApplicationContext } from '../context/ApplicationContext';

interface WellListItem {
  // eslint-disable-next-line camelcase
  target_name: string;
  // eslint-disable-next-line camelcase
  well_id: number;
}

export type WellList = Array<WellListItem>;

export interface CampaignPayload {
  id?: number;
  field: string;
  name: string;
  created?: string;
  // eslint-disable-next-line camelcase
  well_list: WellList;
}

export const useCreateCampaign = () => {
  const [isCreating, setCreating] = React.useState<boolean>(false);
  const [createError, setCreateError] = React.useState<boolean>(false);
  const { field, setCampaigns, setSelectedCampaign } = useApplicationContext();

  const createCampaign = async (name: string, wellList: WellList) => {
    setCreating(true);
    setCreateError(false);

    const payload = {
      field: `${field}`,
      name,
      well_list: wellList,
    };

    try {
      const result = await apiCreateCampaign(payload);

      setSelectedCampaign(result.data);
      setCampaigns(campaigns => [...campaigns, result.data])

      setCreating(false);
    } catch {
      setCreateError(true);
      setCreating(false);
    }
  };

  return {
    isCreating,
    createCampaign,
    createError,
  };
};

export const useUpdateCampaign = () => {
  const [isUpdating, setUpdating] = React.useState<boolean>(false);
  const [updateError, setUpdateError] = React.useState<boolean>(false);
  const { field, setCampaigns, selectedCampaign, setSelectedCampaign } = useApplicationContext();

  const updateCampaign = async (campaignId: number, name: string, wellList: WellList) => {
    setUpdateError(false);
    setUpdating(true);

    const payload = {
      id: campaignId,
      field: `${field}`,
      name,
      well_list: wellList,
    };

    try {
      const response = await apiUpdateCampaign(payload);
      setCampaigns(campaigns => campaigns.map(campaign => {
        if(campaign.id === campaignId) {
          return response.data
        }
        return campaign
      }))
      if(selectedCampaign?.id === campaignId) {
        setSelectedCampaign(payload)
      }

      setUpdating(false);
    } catch {
      setUpdateError(true);
      setUpdating(false);
    }
  };

  return {
    isUpdating,
    updateCampaign,
    updateError,
  };
};

export const useRemoveCampaign = () => {
  const [isRemoving, setRemoving] = React.useState<boolean>(false);
  const [removeError, setRemoveError] = React.useState<boolean>(false);
  const { setCampaigns, selectedCampaign, setSelectedCampaign } = useApplicationContext();

  const removeCampaign = async (campaignId: number) => {
    setRemoving(true);
    setRemoveError(false);

    try {
      await apiRemoveCampaign(campaignId);
      setCampaigns(campaigns => campaigns.filter(campaign => campaign.id !== campaignId))
      if(selectedCampaign?.id === campaignId) {
        setSelectedCampaign(null)
      }

      setRemoving(false);
    } catch {
      setRemoveError(true);
      setRemoving(false);
    }
  };

  return {
    isRemoving,
    removeCampaign,
    removeError,
  };
};

export const useFetchCampaigns = () => {
  const [isFetching, setFetching] = React.useState<boolean>(false);
  const [fetchError, setError] = React.useState<boolean>(false);
  const { field, setCampaigns } = useApplicationContext();

  const refreshList = async () => {
    setFetching(true);

    try {
      const result = await fetchAllCampaigns(`${field}`);

      setCampaigns(result.data);
      setFetching(false);
    } catch {
      setError(true);
      setFetching(false);
    }
  };

  return {
    refreshList,
    isFetching,
    fetchError,
  };
};
