import * as React from 'react';
import * as THREE from 'three';
import { useDebounce } from 'use-debounce';

import { CONSTRAINT_DEBOUNCE_TIMEOUT } from '../constants/Constants';
import { Target, TargetWell } from '../types/Targets';
import { filesLoadedReducer } from './files-loaded-reducer';
import { CampaignPayload } from '../hooks/campaign';
import { ManualWellsData } from '../hooks/ManualWellsLoader';

export enum Field {
  NIMR_A = 'nimr_a',
  NIMR_E = 'nimr_e',
  NIMR_E_INJ = 'nimr_e_inj',
}

export interface HorizonObject {
  name: string;
  id: number;
  visible: boolean;
}

export interface RawData {
  coords: number[][];
  id: number;
  name: string;
  target?: string;
  area?: string;
  flipped?: boolean;
  // eslint-disable-next-line camelcase
  surface_x?: number;
  // eslint-disable-next-line camelcase
  surface_y?: number;
  // eslint-disable-next-line camelcase
  surface_inc?: number;
  // eslint-disable-next-line camelcase
  max_dog_leg?: number;
  // eslint-disable-next-line camelcase
  azimuth_change?: number;
}

export interface Constraints {
  easting: number;
  northing: number;
  rerouteRoads: boolean;
  rerouteFlowLines: boolean;
  rerouteCables: boolean;
  wellCost: number;
  drainLength: number;
  wellLength: number;
  minCCDistanceOverburden: number;
  minCCDistanceDrain: number;
  outstep: number;
  maxDlsShallowestSection: number;
  maxDlsDeepestSection: number;
  maxAzimuthalChange: number;
  kickOffDepth: number;
}

interface ConstraintRange {
  min: number;
  max: number;
}

export interface ConstraintRanges {
  easting: ConstraintRange;
  northing: ConstraintRange;
  wellCost: ConstraintRange;
  drainLength: ConstraintRange;
  wellLength: ConstraintRange;
  minCCDistanceOverburden: ConstraintRange;
  minCCDistanceDrain: ConstraintRange;
  outstep: ConstraintRange;
  maxDlsShallowestSection: ConstraintRange;
  maxDlsDeepestSection: ConstraintRange;
  maxAzimuthalChange: ConstraintRange;
  kickOffDepth: ConstraintRange;
}

export type ConstraintRangesKeys = Extract<keyof ConstraintRanges, string>

const initialConstraints: Constraints = {
  easting: 0,
  northing: 0,
  rerouteRoads: false,
  rerouteFlowLines: false,
  rerouteCables: false,
  wellCost: 0,
  drainLength: 0,
  wellLength: 0,
  minCCDistanceOverburden: 0,
  minCCDistanceDrain: 0,
  outstep: 0,
  maxDlsShallowestSection: 15,
  maxDlsDeepestSection: 15,
  maxAzimuthalChange: 0,
  kickOffDepth: 0,
};

const initialConstraintRanges: ConstraintRanges = {
  easting: {
    min: 0,
    max: 100,
  },
  northing: {
    min: 0,
    max: 100,
  },
  wellCost: {
    min: 0,
    max: 100,
  },
  drainLength: {
    min: 0,
    max: 100,
  },
  wellLength: {
    min: 0,
    max: 100,
  },
  minCCDistanceOverburden: {
    min: 0,
    max: 100,
  },
  minCCDistanceDrain: {
    min: 0,
    max: 100,
  },
  outstep: {
    min: 0,
    max: 100,
  },
  maxDlsShallowestSection: {
    min: 5,
    max: 15,
  },
  maxDlsDeepestSection: {
    min: 5,
    max: 15,
  },
  maxAzimuthalChange: {
    min: 0,
    max: 100,
  },
  kickOffDepth: {
    min: 0,
    max: 100,
  },
};

export interface FieldObject {
  geometry: THREE.BufferGeometry[];
  id: number;
  interactive: boolean;
  rawdata: RawData[];
  title: string;
  type: 'existing_wells' | 'horizon' | 'constraint' | 'faults';
  visible: boolean;
}

export enum LoginStatus {
  LoggedOff,
  Validating,
  LoggedOn,
}

export interface SelectedWell {
  target: string;
  wellId: number;
}

interface SelectedWellData {
  target: Target;
  well: TargetWell;
}

interface MapChart {
  dip: number[][];
  anomaly: number[][];
}

interface State {
  field: Field;
  setField: React.Dispatch<React.SetStateAction<Field>>;

  manualWellsData: ManualWellsData | null;
  setManualWellsData: React.Dispatch<React.SetStateAction<ManualWellsData | null>>;
  horizons: HorizonObject[];
  setHorizons: React.Dispatch<React.SetStateAction<HorizonObject[]>>;
  showCredits: boolean;
  setShowCredits: React.Dispatch<React.SetStateAction<boolean>>;

  allowReroutingAccessRoads: boolean;
  setAllowReroutingAccessRoads: React.Dispatch<React.SetStateAction<boolean>>;
  allowReroutingCathodicFeatures: boolean;
  setAllowReroutingCathodicFeatures: React.Dispatch<React.SetStateAction<boolean>>;
  allowReroutingBuriedPowerlines: boolean;
  setAllowReroutingBuriedPowerlines: React.Dispatch<React.SetStateAction<boolean>>;
  onlyOutsidePolymerInjectionArea: boolean;
  setOnlyOutsidePolymerInjectionArea: React.Dispatch<React.SetStateAction<boolean>>;

  drainDistanceValid: boolean;
  setDrainDistanceValid: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCampaign: CampaignPayload | null;
  setSelectedCampaign: React.Dispatch<React.SetStateAction<CampaignPayload | null>>;
  campaigns: Array<CampaignPayload>;
  setCampaigns: React.Dispatch<React.SetStateAction<Array<CampaignPayload>>>;
  filesAvailable: number;
  setFilesAvailable: React.Dispatch<React.SetStateAction<number>>;
  targetsFlipped: boolean;
  setTargetsFlipped: React.Dispatch<React.SetStateAction<boolean>>;
  filesLoaded: number;
  increaseFilesLoaded: () => void;
  resetFilesLoaded: () => void;
  getSelectedTarget: () => Target | null;
  mapChart: MapChart;
  setMapChart: React.Dispatch<React.SetStateAction<MapChart>>;
  targets: string[];
  selectedTargets: string[];
  setSelectedTargets: React.Dispatch<React.SetStateAction<string[]>>;
  setTargets: React.Dispatch<React.SetStateAction<string[]>>;
  targetsLoaded: string[];
  setTargetsLoaded: React.Dispatch<React.SetStateAction<string[]>>;
  targetsData: Target[];
  setTargetsData: React.Dispatch<React.SetStateAction<Target[]>>;
  editWell: SelectedWell | null;
  setEditWell: React.Dispatch<React.SetStateAction<SelectedWell | null>>;
  selectedWell: SelectedWell | null;
  setSelectedWell: React.Dispatch<React.SetStateAction<SelectedWell | null>>;
  getSelectedWell: () => SelectedWellData | null;
  getEditWell: () => SelectedWellData | null;
  constraints: Constraints;
  constraintRanges: ConstraintRanges;
  setConstraints: React.Dispatch<React.SetStateAction<Constraints>>;
  setConstraintRanges: React.Dispatch<React.SetStateAction<ConstraintRanges>>;
  targetFocus: Target | null;
  setTargetFocus: React.Dispatch<React.SetStateAction<Target | null>>;
  loadedFieldObjects: FieldObject[];
  setLoadedFieldObjects: React.Dispatch<React.SetStateAction<FieldObject[]>>;
  horizonObject: FieldObject | null;
  setHorizonObject: React.Dispatch<React.SetStateAction<FieldObject | null>>;
  campaignWells: TargetWell[];
  setCampaignWells: React.Dispatch<React.SetStateAction<TargetWell[]>>;
  objectsOffset: number[] | null;
  setObjectsOffset: React.Dispatch<React.SetStateAction<number[] | null>>;
  setLoginStatus: React.Dispatch<React.SetStateAction<LoginStatus>>;
  loginStatus: LoginStatus;
  onLogout: () => void;
  showNotification: string;
  setShowNotification: React.Dispatch<string>;
  notificationID: number;
  setNotificationType: React.Dispatch<React.SetStateAction<string>>;
  notificationType: string;
  setNotificationID: React.Dispatch<number>;
  setNewNotification: (text: string, notificationType?: string) => void;
}

const ApplicationContext = React.createContext<State | undefined>(undefined);

interface ApplicationProviderProps {
  children: React.ReactNode;
}

export const ApplicationProvider = ({ children }: ApplicationProviderProps) => {
  const [field, setField] = React.useState<Field>(Field.NIMR_A);

  const [manualWellsData, setManualWellsData] = React.useState<ManualWellsData | null>(null);
  const [horizons, setHorizons] = React.useState<HorizonObject[]>([]);
  const [showCredits, setShowCredits] = React.useState<boolean>(false);
  const [allowReroutingAccessRoads, setAllowReroutingAccessRoads] = React.useState<boolean>(true);
  const [allowReroutingCathodicFeatures, setAllowReroutingCathodicFeatures] = React.useState<boolean>(true);
  const [allowReroutingBuriedPowerlines, setAllowReroutingBuriedPowerlines] = React.useState<boolean>(true);
  const [onlyOutsidePolymerInjectionArea, setOnlyOutsidePolymerInjectionArea] = React.useState<boolean>(true);
  const [drainDistanceValid, setDrainDistanceValid] = React.useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = React.useState<CampaignPayload | null>(null);
  const [campaigns, setCampaigns] = React.useState<Array<CampaignPayload>>([]);
  const [filesAvailable, setFilesAvailable] = React.useState<number>(0);
  const [targetsFlipped, setTargetsFlipped] = React.useState<boolean>(false);
  const [filesLoaded, dispatchFilesLoaded] = React.useReducer<React.Reducer<number, string>>(filesLoadedReducer, 0);
  const [mapChart, setMapChart] = React.useState<MapChart>({ dip: [], anomaly: [] });
  const [loginStatus, setLoginStatus] = React.useState<LoginStatus>(LoginStatus.Validating);
  const [loadedFieldObjects, setLoadedFieldObjects] = React.useState<FieldObject[]>([]);
  const [horizonObject, setHorizonObject] = React.useState<FieldObject | null>(null);
  const [campaignWells, setCampaignWells] = React.useState<TargetWell[]>([]);
  const [targets, setTargets] = React.useState<string[]>([]);
  const [targetsLoaded, setTargetsLoaded] = React.useState<string[]>([]);
  const [targetsData, setTargetsData] = React.useState<Target[]>([]);
  const [selectedTargets, setSelectedTargets] = React.useState<string[]>([]);
  const [selectedWell, setSelectedWell] = React.useState<SelectedWell | null>(null);
  const [editWell, setEditWell] = React.useState<SelectedWell | null>(null);
  const [constraints, setConstraints] = React.useState<Constraints>(initialConstraints);
  const [constraintRanges, setConstraintRanges] = React.useState<ConstraintRanges>(initialConstraintRanges);
  const [targetFocus, setTargetFocus] = React.useState<Target | null>(null);
  const [objectsOffset, setObjectsOffset] = React.useState<number[] | null>([0, 0, 0]);
  const [showNotification, setShowNotification] = React.useState<string>('');
  const [notificationID, setNotificationID] = React.useState<number>(0);
  const [notificationType, setNotificationType] = React.useState<string>('');

  const [debouncedConstraints] = useDebounce(constraints, CONSTRAINT_DEBOUNCE_TIMEOUT);

  const increaseFilesLoaded = () => {
    dispatchFilesLoaded('increase');
  };

  const resetFilesLoaded = () => {
    dispatchFilesLoaded('reset')
  }

  const setNewNotification = (text: string, newNotificationType = '') => {
    setNotificationType(newNotificationType);
    setShowNotification(text);
    setNotificationID(Date.now());
  };

  const handleLogout = (): void => {
    localStorage.removeItem('token');
    setLoginStatus(LoginStatus.LoggedOff);
  };

  const getSelectedTarget = (): Target | null => {
    const name = selectedTargets.length ? selectedTargets[0] : null;

    if (!name) {
      return null;
    }

    const result = targetsData.find((target) => target.title === name);

    return result || null;
  };

  const getSelectedWell = React.useCallback((): SelectedWellData | null => {
    if (!selectedWell) {
      return null;
    }

    const target = targetsData.find((findTarget) => findTarget.title === selectedWell.target);
    const well = target!.wells.find((data) => data.id === selectedWell.wellId);

    if (!well) {
      return null;
    }

    return {
      target: target!,
      well,
    };
  }, [selectedWell, targetsData]);

  const getEditWell = React.useCallback((): SelectedWellData | null => {
    if (!editWell) {
      return null;
    }

    const target = targetsData.find((findTarget) => findTarget.title === editWell.target);
    const well = target!.wells.find((data) => data.id === editWell.wellId);

    if (!well) {
      return null;
    }

    return {
      target: target!,
      well,
    };
  }, [editWell, targetsData]);

  React.useEffect(() => {
    const storedField = localStorage.getItem('field')
    if(storedField !== null && (Object.values(Field) as string[]).includes(storedField)) {
      setField(storedField as Field)
    }
  }, [])

  React.useEffect(() => {
    localStorage.setItem('field', field)  
    setSelectedCampaign(null)
    setCampaignWells([])
  }, [field])

  return (
    <ApplicationContext.Provider
      value={{
        field,
        setField,

        manualWellsData,
        setManualWellsData,
        horizons,
        setHorizons,
        horizonObject,
        setHorizonObject,
        setShowCredits,
        showCredits,

        allowReroutingAccessRoads, 
        setAllowReroutingAccessRoads,
        allowReroutingCathodicFeatures, 
        setAllowReroutingCathodicFeatures,
        allowReroutingBuriedPowerlines, 
        setAllowReroutingBuriedPowerlines,
        onlyOutsidePolymerInjectionArea,
        setOnlyOutsidePolymerInjectionArea,

        drainDistanceValid,
        setDrainDistanceValid,
        selectedCampaign,
        setSelectedCampaign,
        campaigns,
        setCampaigns,
        targetsFlipped,
        setTargetsFlipped,
        filesLoaded,
        increaseFilesLoaded,
        resetFilesLoaded,
        filesAvailable,
        setFilesAvailable,
        getSelectedTarget,
        mapChart,
        setMapChart,
        loginStatus,
        setLoginStatus,
        loadedFieldObjects,
        setLoadedFieldObjects,

        targetsLoaded,
        setTargetsLoaded,

        targetsData,
        setTargetsData,
        editWell,
        setEditWell,
        setSelectedWell,
        selectedWell,
        getSelectedWell,
        getEditWell,
        constraints: debouncedConstraints,
        setConstraints,
        setConstraintRanges,
        constraintRanges,
        campaignWells,
        setCampaignWells,
        targetFocus,
        setTargetFocus,
        objectsOffset,
        setObjectsOffset,
        targets,
        selectedTargets,
        setTargets,
        setSelectedTargets,
        onLogout: handleLogout,
        showNotification,
        setShowNotification,
        notificationID,
        setNotificationID,
        setNewNotification,
        setNotificationType,
        notificationType,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = (): State => {
  const context = React.useContext(ApplicationContext);

  if (!context) {
    throw new Error('useApplicationContext must be used within an ApplicationProvider');
  }

  return context;
};
