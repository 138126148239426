import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import css from './RangeSlider.module.scss';

interface RangeSliderProps {
  title: string;
  id: string | number;
  min: number;
  max: number;
  value: string | number;
  step: number;
  setValue?: (value: number, id: string) => void;
  inverted: boolean;
  minMaxDecimals?: number;
  valueDecimals?: number;
  longValues?: boolean;
}

const SLIDER_WIDTH = 165;
const SLIDER_LONG_VALUES_WIDTH = 109;

const RangeSlider = React.memo<RangeSliderProps>(
  ({
    title,
    id,
    min = 0.0,
    max = 90.0,
    value = 50.0,
    step = 0.1,
    setValue,
    inverted = false,
    minMaxDecimals = 0,
    valueDecimals = 1,
    longValues = false,
  }) => {
    const slider_width = longValues ? SLIDER_LONG_VALUES_WIDTH : SLIDER_WIDTH

    const [sliderValue, setSliderValue] = useState(50.0);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const parsedFloat = parseFloat(event.target.value);

      setSliderValue(parsedFloat);

      if (setValue) {
        setValue(parsedFloat, `${id}`);
      }
    };

    useEffect(() => {
      setSliderValue(parseFloat(`${value}`));
    }, [value]);

    return (
      <div className={classNames(css.root, { [css.longValues]: longValues})}>
        <div className={css.title}>{title}</div>
        <div className={css.values}>
          <span className={css.min}>{min.toFixed(minMaxDecimals)}</span>
          <span className={css.slider}>
            <input
              id={id + '_slider'}
              type="range"
              min={min}
              max={max}
              step={step}
              value={sliderValue.toFixed(1)}
              className={css.range}
              onChange={handleOnChange}
            />
            <div
              className={inverted ? css.fillTrackRight : css.fillTrackLeft}
              style={{
                width: inverted
                  ? `${((slider_width * (sliderValue - max)) / (min - max)).toFixed(0)}px`
                  : `${((slider_width * (sliderValue - min)) / (max - min)).toFixed(0)}px`,
              }}
            />
          </span>
          <span className={css.max}>{max.toFixed(minMaxDecimals)}</span>
          <span className={css.box}>
            <input id={id + '_box'} type="number" value={sliderValue.toFixed(valueDecimals)} onChange={handleOnChange} />
          </span>
        </div>
      </div>
    );
  },
);

export { RangeSlider };
