import * as React from 'react';

import { useApplicationContext } from '../../context/ApplicationContext';
import { useElementDimensions } from '../../hooks/Dimensions';
import { SideViewRender } from '../molecules/SideViewRender';

import css from './WellSideView.module.scss';

interface WellSideViewProps {
  tileTrigger?: boolean;
}

const WellSideView = React.memo<WellSideViewProps>((props) => {
  const { tileTrigger } = props;
  const { getSelectedWell } = useApplicationContext();
  const ref = React.useRef<HTMLDivElement>(null);
  const dimensions = useElementDimensions(ref, tileTrigger);

  const width = dimensions.width < dimensions.height ? dimensions.width : dimensions.height;
  const height = dimensions.height < dimensions.width ? dimensions.height : dimensions.width;

  const selectedWell = getSelectedWell();

  const convertData = (data: number[][]) => data.map((item) => ({ x: item[0], y: -item[1] }));

  const getDomains = () => {
    if (!selectedWell) {
      return {
        xDomain: [0, -400],
        yDomain: [-200, 200],
      };
    }

    const { well } = selectedWell;

    const ranges = well.projectionRanges;

    return {
      xDomain: [ranges.x.min, ranges.x.max],
      yDomain: [-ranges.y.max, -ranges.y.min],
    };
  };

  const domains = getDomains();

  const sideViewData = convertData(selectedWell ? selectedWell.well.projectedCoordinates : []);
  const kolmuleData = convertData(selectedWell ? selectedWell.well.kolmuleProjectedCoordinates : []);
  const topStoeData = convertData(selectedWell ? selectedWell.well.topStoeProjectedCoordinates : []);
  const bottomStoeData = convertData(selectedWell ? selectedWell.well.bottomStoeProjectedCoordinates : []);
  const seaFloorData = convertData(selectedWell ? selectedWell.well.seaFloorProjectedCoordinates : []);
  const tangentData = convertData(selectedWell ? selectedWell.well.tangentProjectionCoordinates : []);
  const fuglenData = convertData(selectedWell ? selectedWell.well.fuglenProjectionCoordinates : []);
  const faultData = selectedWell ? selectedWell.well.faultProjectedCoordinates.map((fault) => convertData(fault)) : [];

  return (
    <div className={css.root} ref={ref}>
      <div className={css.container}>
        {selectedWell && (
          <SideViewRender
            domains={domains}
            data={sideViewData}
            width={width - 45}
            height={height - 45}
            kolmuleData={kolmuleData}
            topStoeData={topStoeData}
            bottomStoeData={bottomStoeData}
            seaFloorData={seaFloorData}
            tangentData={tangentData}
            faultData={faultData}
            fuglenData={fuglenData}
          />
        )}
      </div>
    </div>
  );
});

WellSideView.displayName = 'WellSideView';

export { WellSideView };
