import * as React from 'react';
import { useHistory } from 'react-router-dom';

import { PageContent } from '../../components/templates/PageContent';
import { AppHeader } from '../../components/templates/AppHeader';
import { FlowNavigation } from '../../components/organisms/FlowNavigation';

const LandingPage = React.memo(() => {
  const history = useHistory();

  React.useEffect(() => {
    history.push('/target-view');
  }, [history]);

  return (
    <div>
      <AppHeader />
      <FlowNavigation />
      <PageContent>&nbsp;</PageContent>
    </div>
  );
});

LandingPage.displayName = 'LandingPage';

export { LandingPage };
