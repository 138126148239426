import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { project, viewport } from '../../config.json';
import { useWindowDimensions } from '../../hooks/Dimensions';
import { SmallWindow } from './SmallWindow';
import { LandingPage } from '../../features/landing-page/LandingPage';
import { useMeshData } from '../../hooks/MeshData';
import { useApplicationContext } from '../../context/ApplicationContext';
import { TargetView } from '../../features/target-view/TargetView';
import { useTargetsLoader } from '../../hooks/TargetsLoader';
import { CampaignView } from '../../features/campaign-view/CampaignView';
import { useSetRanges } from '../../hooks/SetRanges';
import Notification from '../atoms/Notification';
import { LoadingBar } from '../atoms/LoadingBar';
import { AllWellsView } from '../../features/all-wells-view/AllWellsView';

import '../../scss/app.scss';
import { useManualWellsLoader } from '../../hooks/ManualWellsLoader';
import { uniq } from 'lodash';
import { TargetWell } from 'types/Targets';

const HORIZONS_FILE_COUNT = 1;

const { faults, horizons } = viewport.meshes;
const { targets: initialTargets } = project;

const App: React.FC = (): React.ReactElement => {
  const { width: currentWidth } = useWindowDimensions();
  const smallWindow = currentWidth < project.min_window_with;
  const { fetchManualWellsData } = useManualWellsLoader();
  const { loadTargets } = useTargetsLoader(initialTargets);
  const { setRanges } = useSetRanges();
  const {
    field, objectsOffset, setFilesAvailable, 
    filesLoaded, resetFilesLoaded, filesAvailable,
    targets, setTargets,
    targetsLoaded, setTargetsLoaded,
    targetsData, setTargetsData,
    selectedTargets,
    campaigns, setCampaignWells, selectedCampaign,
  } = useApplicationContext();
  const { getHorizonData } = useMeshData(faults, horizons[field]);

  React.useEffect(() => {
    if (targetsData.length > 0) {
      setRanges();
    }
  }, [targetsData]);

  React.useEffect(() => {
    if (selectedTargets.length > 0) {
      setRanges();
    }
  }, [JSON.stringify(selectedTargets)]);

  React.useEffect(() => {
    console.log('initial field', field)
    resetFilesLoaded();
    setTargetsLoaded([]);
    setTargetsData([]);
    // load only the first file initially
    setFilesAvailable(initialTargets[field].slice(0, 1).length + HORIZONS_FILE_COUNT);
    setTargets(initialTargets[field]);
    loadTargets(field, initialTargets[field].slice(0, 1));
  }, [field]);

  React.useEffect(() => {
    if (objectsOffset && objectsOffset.length > 0 && objectsOffset[0] !== 0) {
      fetchManualWellsData();
      getHorizonData();
    }
  }, [objectsOffset]);

  React.useEffect(() => {
    const targetsToLoad = uniq([
      // load all selected targets
      ...selectedTargets,
      // load all campaign targets
      ...(selectedCampaign?.well_list.map(well => well.target_name) || [])
    ].sort())
      // only if not loaded
      .filter(targetName => !targetsLoaded.includes(targetName))
      // only if actually exists
      .filter(targetName => targets.includes(targetName))

    if(targetsToLoad.length > 0) {
      resetFilesLoaded();
      setFilesAvailable(targetsToLoad.length);
      loadTargets(field, targetsToLoad);
    }

  }, [selectedTargets, targetsLoaded, selectedCampaign, targets])

  React.useEffect(() => {
    // update campaignWells when targets are loaded
    const campaignWells = selectedCampaign?.well_list.map(campaignWell => {
      const target = targetsData.find(target => target.title === campaignWell.target_name)
      if(!target) return undefined
      return target.wells.find(well => well.wellId === campaignWell.well_id)
    }).filter(well => well !== undefined)

    if(campaignWells !== undefined) {
      setCampaignWells(campaignWells as TargetWell[])
    }
  }, [targetsLoaded, targetsData, selectedCampaign])

  const percentage = 100 * (filesLoaded / filesAvailable);
  const isLoading = filesLoaded < filesAvailable;

  return (
    <>
      {isLoading && <LoadingBar percentage={percentage} />}
      {smallWindow ? (
        <SmallWindow minimumWidth={project.min_window_with} currentWidth={currentWidth} />
      ) : (
        <>
          <Switch>
            <Route exact path="/target-view" component={TargetView} />
            <Route exact path="/campaign-view" component={CampaignView} />
            <Route exact path="/all-wells-view" component={AllWellsView} />
            <Route component={LandingPage} />(
          </Switch>
          <Notification />
        </>
      )}
    </>
  );
};

export { App };
