import * as React from 'react';
import ReactDOM from 'react-dom';

import {
  Modal,
  StructuredListWrapper,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
} from 'carbon-components-react';

import logoWhiteSpace from 'img/landing/white-space-mark-white.png';
import { useApplicationContext } from '../../context/ApplicationContext';

import css from './Credits.module.scss';

const Credits = () => {
  const { showCredits, setShowCredits } = useApplicationContext();

  // Beautiful people, just beautiful
  const credits = [
    { name: 'Daniel Roberts', role: 'Front-end' },
    { name: 'Matteo Bedognetti', role: 'Algorithms' },
    { name: 'Aishah Helena Sharif', role: 'Algorithms' },
    { name: 'Sanne de Roever', role: 'Algorithms' },
    { name: 'Roberto Cota', role: 'Algorithms' },
    { name: 'Michael van Elk', role: 'DevOps / Deployment' },
    { name: 'Bart Meinders', role: '3D visuals' },
  ];

  const handleCloseCredits = () => {
    setShowCredits(false);
  };

  return (
    <>
      {showCredits
        && ReactDOM.createPortal(
          <Modal
            modalHeading="Proudly designed and developed by White Space Energy"
            open
            passiveModal
            onRequestClose={handleCloseCredits}
            size="sm"
            className={`dark-theme ${css.creditsModal}`}
          >
            <>
              <StructuredListWrapper ariaLabel="Developer credits">
                <StructuredListBody>
                  {credits.map((credit) => (
                    <StructuredListRow>
                      <StructuredListCell>{credit.name}</StructuredListCell>
                      <StructuredListCell>{credit.role}</StructuredListCell>
                    </StructuredListRow>
                  ))}
                </StructuredListBody>
              </StructuredListWrapper>
              <a href="https://whitespace.energy/">
                <img src={logoWhiteSpace} alt="White Space Energy" />
              </a>
            </>
          </Modal>,
          document.body,
        )}
    </>
  );
};

export { Credits };
