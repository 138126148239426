import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { checkToken } from '../../api/checkToken';
import { Login } from './Login';
import { App } from '../../components/layout/App';
import {
  LoginStatus,
  useApplicationContext,
} from '../../context/ApplicationContext';

const LoginContainer: React.FC = (): JSX.Element => {
  const history = useHistory();
  const { loginStatus, setLoginStatus } = useApplicationContext();

  useEffect(() => {
    async function persistentLogin() {
      const isTokenValid = await checkToken();

      if (isTokenValid) {
        setLoginStatus(LoginStatus.LoggedOn);
      } else {
        setLoginStatus(LoginStatus.LoggedOff);
      }
    }

    persistentLogin().catch((): void => {
      setLoginStatus(LoginStatus.LoggedOff);
    });
  }, [history, loginStatus, setLoginStatus]);

  const handleLogin = (state: boolean) => {
    if (state) {
      setLoginStatus(LoginStatus.LoggedOn);
    } else {
      setLoginStatus(LoginStatus.LoggedOff);
    }
  };

  return loginStatus === LoginStatus.LoggedOn ? (
    <App />
  ) : (
    <Login
      validating={loginStatus === LoginStatus.Validating}
      handleLogin={handleLogin}
    />
  );
};

export { LoginContainer };
