import * as React from 'react';
import classNames from 'classnames';

import { Maximize16, Minimize16 } from '@carbon/icons-react';

import css from './ExpandIcon.module.scss';

interface ExpandIconProps {
  onClickHandle?: (name: string) => void;
  name: string;
  collapse: string;
  iconColor?: string;
  expanded: string;
  inverse?: boolean;
}

const ExpandIcon = React.memo<ExpandIconProps>(
  ({ onClickHandle, name, iconColor, expanded, collapse, inverse = false }) => {
    const color: { color?: string } = {};
    const maximized = name === expanded;
    const toggleState = () => {
      if (onClickHandle) {
        if (name === expanded) {
          onClickHandle(collapse);
        } else {
          onClickHandle(name);
        }
      }
    };

    if (iconColor) {
      color.color = iconColor;
    }

    return (
      <div className={classNames(css.root, { [css.inverse]: inverse })} onClick={toggleState} style={color}>
        {maximized ? <Minimize16 style={color} /> : <Maximize16 style={color} />}
      </div>
    );
  },
);

export { ExpandIcon };
