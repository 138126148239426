import * as React from 'react';
import { useApplicationContext } from '../../context/ApplicationContext';
import { useConstrainedTargetData } from '../../hooks/ConstrainedTargetData';
import { TargetMapToolbox } from '../atoms/TargetMapToolbox';

import css from './TargetMapContent.module.scss';

import UP_AccessRoad from 'json/constraints/soft_constraints/UP_AccessRoad.json';
import Cathodic_Features from 'json/constraints/soft_constraints/Cathodic_Features.json';
import Buried_Powerlines from 'json/constraints/soft_constraints/Buried_Powerlines.json';
import WELLS_LOCATIONBuffer from 'json/constraints/hard_constraints/WELLS_LOCATIONBuffer.json';
import Road_Centreline_Buffer from 'json/constraints/hard_constraints/Road_Centreline_Buffer.json';
import NoGoBuffer from 'json/constraints/hard_constraints/NoGoBuffer.json';
import Flowline_Buffer from 'json/constraints/hard_constraints/Flowline_Buffer.json';
import PowerlineBuffer from 'json/constraints/hard_constraints/PowerlineBuffer.json';
import Pipeline_Buffer from 'json/constraints/hard_constraints/Pipeline_Buffer.json';
import { targetMap } from '../../config.json';

const LAYERS_SOFT_CONSTRAINTS = {
  UP_AccessRoad,
  Cathodic_Features,
  Buried_Powerlines,
};

const LAYERS_HARD_CONSTRAINTS = {
  WELLS_LOCATIONBuffer,
  Road_Centreline_Buffer,
  NoGoBuffer,
  Flowline_Buffer,
  PowerlineBuffer,
  Pipeline_Buffer,
};

const TargetMapContent = (props) => {
  const {
    field,
    getSelectedWell,
    targetsData,
    selectedTargets,
    manualWellsData,
  } = useApplicationContext();
  const { constrainedSelectedTargetsData } = useConstrainedTargetData();

  const containerRef = React.useRef(null);

  const [isTargetWellsVisible, setTargetWellsVisible] = React.useState(true);
  const [isExistingWellsVisible, setExistingWellsVisible] = React.useState(true);
  const [isHardConstraintsVisible, setHardConstraintsVisible] = React.useState(true);
  const [softConstraintsVisible, setSoftConstraintsVisible] = React.useState(Object.keys(LAYERS_SOFT_CONSTRAINTS));
  const [isZoomedIn, setZoomedIn] = React.useState(false);

  const selectedWell = getSelectedWell();

  const handleZoomedIn = (zoomedIn) => {
    if (selectedTargets.length === 0) {
      setZoomedIn(false);

      return;
    }

    setZoomedIn(zoomedIn);
  };

  const handleToggleTargetWells = () => {
    setTargetWellsVisible(!isTargetWellsVisible);
  };

  const handleToggleExistingWells = () => {
    setExistingWellsVisible(!isExistingWellsVisible);
  };

  const handleToggleVisibility = (name) => {
    if (name === 'hard-constraints') {
      setHardConstraintsVisible(!isHardConstraintsVisible);
    }
  };

  const handleToggleSoftConstraint = (name) => {
    console.log(name, softConstraintsVisible.includes(name));
    if (softConstraintsVisible.includes(name)) {
      setSoftConstraintsVisible(softConstraintsVisible.filter((constraint_name) => constraint_name !== name));
    } else {
      setSoftConstraintsVisible([...softConstraintsVisible, name]);
    }
  };

  const wells = manualWellsData?.wells?.map((well) => ({
    ...well,
    top_coord: well.coords[0],
  }));

  const layerToSVG = (data) => {
    if (data.point || data.pointz) {
      const points = data.point ?? data.pointz;
      return points.map((point, key) => <circle key={key} cx={point.x} cy={point.y} r="25" />);
    }
    if (data.polyline || data.polylinez) {
      const polylines = data.polyline ?? data.polylinez;
      return polylines.map((polyline, key) => (
        <polyline key={key} points={polyline.map(({ x, y }) => `${x},${y}`).join(' ')} />
      ));
    }
    if (data.polygon || data.polygonz) {
      const polygons = data.polygon ?? data.polygonz;
      return polygons.map((polygon, key) => (
        <polygon key={key} points={polygon.map(({ x, y }) => `${x},${y}`).join(' ')} />
      ));
    }
    return null;
  };

  const viewBox = React.useMemo(() => {
    if (isZoomedIn) {
      const wellTopCoordinates = targetsData
        .filter((target) => selectedTargets.includes(target.title))
        .flatMap((target) => target.wells.map((well) => well.coords[0]));
      const allX = wellTopCoordinates.map((coord) => coord[0]);
      const allY = wellTopCoordinates.map((coord) => coord[1]);
      const x_min = Math.min(...allX) - 1000;
      const x_max = Math.max(...allX) + 1000;
      const y_min = Math.min(...allY) - 1000;
      const y_max = Math.max(...allY) + 1000;

      return `${x_min} ${y_min} ${x_max - x_min} ${y_max - y_min}`;
    } else {
      const { bounds } = targetMap[field]
      return `${bounds.x.min} ${bounds.y.min} ${bounds.x.max - bounds.x.min} ${bounds.y.max - bounds.y.min}`
    }
  }, [isZoomedIn, selectedTargets, field]);

  return (
    <div className={css.root} ref={containerRef}>
      <TargetMapToolbox
        toggleTargetWells={handleToggleTargetWells}
        showTargetWells={isTargetWellsVisible}
        toggleExistingWells={handleToggleExistingWells}
        showExistingWells={isExistingWellsVisible}
        toggleVisibility={handleToggleVisibility}
        isHardConstraintsVisible={isHardConstraintsVisible}
        toggleSoftConstraint={handleToggleSoftConstraint}
        softConstraints={Object.keys(LAYERS_SOFT_CONSTRAINTS)}
        softConstrainsVisible={softConstraintsVisible}
        handleZoomedIn={handleZoomedIn}
        isZoomedIn={isZoomedIn}
      />
      <svg className={css.svg} viewBox={viewBox}>
        {isHardConstraintsVisible &&
          Object.entries(LAYERS_HARD_CONSTRAINTS).map(([layer_name, layer], key) => (
            <g key={key} name={layer_name} className={css.hardConstraint}>
              {layerToSVG(layer)}
            </g>
          ))}
        {Object.entries(LAYERS_SOFT_CONSTRAINTS)
          .filter(([layer_name]) => softConstraintsVisible.includes(layer_name))
          .map(([layer_name, layer], key) => (
            <g key={key} name={layer_name} className={css.softConstraint}>
              {layerToSVG(layer)}
            </g>
          ))}
        {isExistingWellsVisible && (
          <g className={css.existingWells}>
            {wells &&
              wells.map((well, key) => <circle key={key} cx={well.top_coord[0]} cy={well.top_coord[1]} r="35" />)}
          </g>
        )}
        {isTargetWellsVisible && (
          <g className={css.targets}>
            {constrainedSelectedTargetsData.map((target) =>
              target.filteredWells.map((well, key) => (
                <circle key={key} cx={well.coords[0][0]} cy={well.coords[0][1]} r="35" />
              )),
            )}
            {selectedWell &&
              constrainedSelectedTargetsData
                .filter((target) => selectedWell.target.title === target.title)
                .map((target) =>
                  target.filteredWells
                    .filter((well) => well.name === selectedWell.well.name)
                    .map((well, key) => (
                      <circle
                        key={key}
                        cx={well.coords[0][0]}
                        cy={well.coords[0][1]}
                        r="35"
                        className={css.highlightedWell}
                      />
                    )),
                )}
          </g>
        )}
      </svg>
    </div>
  );
};

export { TargetMapContent };
