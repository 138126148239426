import * as React from 'react';
import { Row, Column } from 'carbon-components-react';

import css from './CampaignSummary.module.scss';
import { CampaignSummaryTile } from '../atoms/CampaignSummaryTile';
import { useApplicationContext } from '../../context/ApplicationContext';

const CampaignSummary = React.memo(() => {
  const { campaignWells } = useApplicationContext();

  const uniqueTargets = campaignWells
    .filter(
      (campaignWell, index) =>
        campaignWells.findIndex((findCampaignWell) => findCampaignWell.target === campaignWell.target) === index,
    )
    .map((campaignWell) => campaignWell.target);

  const injectorCount = uniqueTargets.reduce<number>((accumulator, nextTarget) => {
    if (campaignWells.some((well) => well.target === nextTarget && well.name.startsWith('W'))) {
      return accumulator + 1;
    }

    return accumulator;
  }, 0);

  const producerCount = uniqueTargets.reduce<number>((accumulator, nextTarget) => {
    if (campaignWells.some((well) => well.target === nextTarget && well.name.startsWith('P'))) {
      return accumulator + 1;
    }

    return accumulator;
  }, 0);

  const minLength = uniqueTargets.reduce<number>((accumulator, nextTarget) => {
    const length = campaignWells.reduce((lengthAccumulator, nextWell) => {
      if (nextWell.target !== nextTarget) {
        return lengthAccumulator;
      }

      return Math.min(nextWell.maxMD, lengthAccumulator);
    }, Infinity);

    return accumulator + length;
  }, 0);

  const totalCost = campaignWells.reduce<number>(
    (totalCostAccumulator, nextWell) => totalCostAccumulator + nextWell.wellCost,
    0,
  );

  const getDrillLength = (): string => {
    if (minLength === 0) {
      return '0';
    }

    return (minLength / 1000).toFixed(2);
  };

  return (
    <div className={css.root}>
      <h3>Campaign Summary</h3>

      <div className={css.tiles}>
        <Row>
          <Column>
            <CampaignSummaryTile value={`${producerCount}`} title="# of producers" />
          </Column>
          <Column>
            <CampaignSummaryTile value={`${injectorCount}`} title="# of injectors" />
          </Column>
        </Row>
        <Row>
          <Column>
            <CampaignSummaryTile value={getDrillLength()} title="total well length (km)" />
          </Column>
          <Column>
            <CampaignSummaryTile value={(totalCost / 1_000_000).toFixed(2)} title={<>total cost<br />(mln USD)</>} />
          </Column>
        </Row>
      </div>
    </div>
  );
});

CampaignSummary.displayName = 'CampaignSummary';

export { CampaignSummary };
